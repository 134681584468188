import Amplify, { Auth, Storage, API } from 'aws-amplify'
import './CollateralAssistance.css'; // Tell webpack that Button.js uses these styles
import React, {Component, Fragment, useRef} from 'react';
import awsconfig from '../aws-exports.js';
import { navigationHandler, routeProps } from '../utilities/navigator.js';
import LoadingIcons from 'react-loading-icons'
import { tokenLifeTimeLimit, checkValidAuthTokenIntervalPeriod_ms,  LENDERBLOCKADMINWHITELIST} from '../blenderData/securityConfigurations';

import 'semantic-ui-css/semantic.min.css'
import { Button, Modal, Header, Image, } from 'semantic-ui-react'

// const Web3 = require('web3');
// var HDWalletProvider = require("@truffle/hdwallet-provider");
var CryptoJS = require("crypto-js");
var AWS = require('aws-sdk');

var mimemessage = require('mimemessage');

var checkValidAuthTokenInterval;

var web3;

const adminCollateralAssistanceDisbursementWallet = '0xb69ee2d67956b4fD5fefA62354E61ed506dbCBa8';

const docClient = new AWS.DynamoDB.DocumentClient({ region: 'us-east-1'});

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

var cognitoID;
var currentUser;
var currentUserCap;
var stripeAccountID;
var stripeCustomerID;
var primaryDisplayCurrency;
var secondaryDisplayCurrency;
var publicKey;
var corporateBackedUsernamesArray;
var web3BalancePre;

let proposeGasPrice;

var campaign;
let client;
const secretName = "PrivateBlenderKeys"
const region = 'us-east-1'


var currentPendingApplicationsList;

let applicantUsername;

let that;

let monthLengtheningEnum = {
    JAN: 'January',
    FEB: 'February',
    MAR: 'March',
    APR: 'April',
    MAY: 'May',
    JUN: 'June',
    JUL: 'July',
    AUG: 'August',
    SEP: 'September',
    OCT: 'October',
    NOV: 'November',
    DEC: 'December',
}

let rand = () => Math.floor(Math.random() * 20) - 10;

let currentPendingApplicantUsernames = []

// const Backdrop = styled("div")`
//   position: fixed;
//   z-index: 1040;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-color: #000;
//   opacity: 0.5;
// `;

// // we use some pseudo random coords so nested modals
// // don't sit right on top of each other.
// const RandomlyPositionedModal = styled(Modal)`
//   position: fixed;
//   width: 400px;
//   z-index: 1040;
//   top: ${() => 50 + rand()}%;
//   left: ${() => 50 + rand()}%;
//   border: 1px solid #e5e5e5;
//   background-color: white;
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
//   padding: 20px;
// `;

const renderBackdrop = () => 
    <div style={{ 
        position: 'fixed',
        zIndex: 1040,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#000',
        opacity: 0.5
    }}/>

class CollateralAssistance extends Component {

    constructor() {
        super()

        this.state = {
            usernameText: '',
            passwordText: '',
            touched: false,
            navigationDestinationObject: {
                navigationRoute: '',
            },
            button_recentApp_opacity: 1,
            button_pendingApp_opacity: 1,
            pendingApplicationsList: [],
            showLoaderOverlay: false,
            contractMapObjectArray: [],
            selectedContractRequest: [],
            givenCollateralAssistanceValueUSD: 0,
            baseStandardCollateralAssistanceDisbursementValueUSD: 0,
            unformatted_requestedCollateralValueUSD: 0,
            contractBalanceWei: null,
            contractBalanceUSD: null,
            insufficientFundsOverlay: false,
        };
        that = this
        currentPendingApplicationsList = []
    }

    checkValidAuthToken(){
        let navigationDestinationObject = {
          navigationRoute: ''
        };
        
        Auth.currentAuthenticatedUser().then((res) => {
            if(res){
    
                currentUser = res.username
        
                if(!LENDERBLOCKADMINWHITELIST.includes(String(currentUser).toLowerCase())){
                    Auth.signOut()
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    }) 
                }
            } else {
                Auth.signOut()
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                }) 
            }
        }).catch((err) => {
            Auth.signOut()
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login',
                },
            }) 
        })

        checkValidAuthTokenInterval = setInterval(() => {
          Auth.currentAuthenticatedUser().then((res) => {
            if(res){
              let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
              let tokenLifetime = Date.now() - authTokenIssueTime
      
              if(tokenLifetime >= tokenLifeTimeLimit){
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                  return navigationHandler(navigationDestinationObject)
                }
              }
            } else {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                    return navigationHandler(navigationDestinationObject)
                }
            }
          }).catch(() => {
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login'
                }
            })
            if(navigationHandler(navigationDestinationObject)){
              return navigationHandler(navigationDestinationObject)
            }
          })
        }, checkValidAuthTokenIntervalPeriod_ms)
    }

    componentWillUnmount(){

    }
    componentDidMount(){
        that.checkValidAuthToken()

        that.initialMountComponents()

        applicantUsername = localStorage.getItem('applicantUsername')

            currentPendingApplicationsList = []

            this.setState({
                usernameText: '',
                passwordText: '',
                touched: false,
                navigationDestinationObject: {
                    navigationRoute: '',
                },
                button_recentApp_opacity: 1,
                button_pendingApp_opacity: 1,
                pendingApplicationsList: [],
                showLoaderOverlay: true,
                contractMapObjectArray: [],
                selectedContractRequest: [],
                givenCollateralAssistanceValueUSD: 0,
                baseStandardCollateralAssistanceDisbursementValueUSD: 0,
                unformatted_requestedCollateralValueUSD: 0,
                contractBalanceWei: null,
                contractBalanceUSD: null,
                insufficientFundsOverlay: false,
            })
            
            Auth.currentAuthenticatedUser().then((res) => {
                if(res){
                    let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
                    let tokenLifetime = Date.now() - authTokenIssueTime
                    if(tokenLifetime < tokenLifeTimeLimit){
                        currentUser = res.username
                        cognitoID = res.signInUserSession.idToken.jwtToken
                                        
                        currentUserCap = currentUser.toUpperCase()
                        if(currentUserCap.length > 10){
                            currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
                        }
                        
                        that.setState({
                            currentUser,
                            navigationDestinationObject: {
                                navigationRoute: '',
                            },
                        })
                    } else {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/login',
                            },
                        })
                    }
                } else {
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    })
                }
            
            }).catch((err) => {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                })
            })
    }

    toggleTouched = () => {
        this.setState( prevState => ({
        touched: !prevState.touched
        }));
    }
    
    handleMouseUp = () => {
        // Handle smooth animation when clicking without holding
        setTimeout( () => {
        this.setState({ touched: false });
        }, 150);
    }



    initialMountComponents(){

        Auth.currentAuthenticatedUser().then((res) => {
            currentUser = res.username
            cognitoID = res.signInUserSession.idToken.jwtToken

            AWS.config.update({region:'us-east-1'});
                            
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: 'us-east-1:8973ceec-5da3-423f-b3c6-e31ebdc08ce8',
                Logins: { 
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_vS08WURWh': cognitoID,
                }
            })

            AWS.config.credentials.refresh((error) => {
                if (error) {
                    return error
                }
            });

            currentUserCap = currentUser.toUpperCase()
            if(currentUserCap.length > 10){
                currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
            }
            that.setState({ 
                currentUser,
                selectedContractRequest: [],
            })
                
            let thisURL = String(`https://api.gnosisscan.io/api?module=account&action=balance&address=${adminCollateralAssistanceDisbursementWallet}&tag=latest&apikey=VJ8T8J3JXCZ2GD35PSVJGE3GKRUB95SURK`)
                
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            // headers.append('Origin','http://localhost:3000');

            fetch(thisURL, {
                mode: 'cors',
                // credentials: 'include',
                method: 'POST',
                headers: headers
            })
                .then(response => response.json())
                .then((blockscout) => {
                    
                    let contractBalanceWei = Number(blockscout.result)
                    let contractBalanceUSD = contractBalanceWei/1000000000000000000
                    that.setState({
                        contractBalanceWei,
                        contractBalanceUSD
                    })

                    API.put('api1169ba1e', '', { 
                        headers: { Authorization: `Bearer ${cognitoID}` },
                        body: { 
                            category: 'db',
                            tableName: 'platformConfigurations', 
                            primaryKey: 'baseStandardCollateralAssistanceDisbursementValueUSD', 
                            requestOperation: 'get',
                        }
                    }).then((_baseStandardCollateralAssistance) => {
                        let baseStandardCollateralAssistanceDisbursementValueUSD = _baseStandardCollateralAssistance.Item.baseStandardCollateralAssistanceDisbursementValueUSD

                        that.setState({
                            givenCollateralAssistanceValueUSD: baseStandardCollateralAssistanceDisbursementValueUSD,
                            baseStandardCollateralAssistanceDisbursementValueUSD
                        })

                        API.put('api1169ba1e', '', { 
                            headers: { Authorization: `Bearer ${cognitoID}` },
                            body: { 
                            category: 'db',
                            tableName: 'general', 
                            primaryKey: 'xdaiGasOracle', 
                            requestOperation: 'get',
                            }
                        }).then((xdaiGasOracle) => { 
                            proposeGasPrice = Number(xdaiGasOracle.Item.xdaiGasOracle.average) * 1000000000

                            API.put('api1169ba1e', '', { 
                                headers: { Authorization: `Bearer ${cognitoID}` },
                                body: { 
                                category: 'db',
                                tableName: 'users', 
                                primaryKey: String(currentUser), 
                                requestOperation: 'get',
                                }
                            }).then((userData) => {
                                
                                primaryDisplayCurrency = userData.Item.preferences.primaryDisplayCurrency
                                secondaryDisplayCurrency = userData.Item.preferences.secondaryDisplayCurrency
                                publicKey = userData.Item.publicKey

                                stripeAccountID = userData.Item.stripeAccountID
                                stripeCustomerID = userData.Item.stripeCustomerID

                                API.put('api1169ba1e', '', { 
                                    headers: { Authorization: `Bearer ${cognitoID}` },
                                    body: { 
                                        category: 'db',
                                        tableName: 'general', 
                                        primaryKey: 'requestedCollateralAssistanceContracts', 
                                        requestOperation: 'get',
                                    }
                                }).then((collateralAssistanceContracts) => {
                                    let requestedCollateralAssistanceContracts = collateralAssistanceContracts.Item.requestedCollateralAssistanceContracts

                                    if(requestedCollateralAssistanceContracts.length == 0){

                                        that.setState({
                                            showLoaderOverlay: false,
                                        })
                                    }










                                    let deniedOrCompletedContractRequestsCount = 0

                                    let alreadyLoopedApplicantArray = []
                                    let contractMapObjectArray = []

                                    for(let v=0; v<=requestedCollateralAssistanceContracts.length-1; v++){
                                        let thisFundedContract = requestedCollateralAssistanceContracts[v]

                                    

                                        API.put('api1169ba1e', '', { 
                                            headers: { Authorization: `Bearer ${cognitoID}` },
                                            body: { 
                                                category: 'db',
                                                tableName: 'verifiedRegistryFundedContracts', 
                                                primaryKey: thisFundedContract, 
                                                requestOperation: 'get',
                                            }
                                        }).then((thisFundedContractObject) => {
                                            if(thisFundedContractObject.hasOwnProperty('Item')){

                                                let requestedCollateralValueWei = thisFundedContractObject.Item.requestedCollateralValueWei
                                                let unformatted_requestedCollateralValueUSD = Number(requestedCollateralValueWei)/1000000000000000000
                                                let requestedCollateralValueUSD = `${unformatted_requestedCollateralValueUSD.toFixed(2)} USD`
                                                let collateralGrantedBool = thisFundedContractObject.Item.collateralGrantedBool
                                                let txnHashOfGrantedCollateral = thisFundedContractObject.Item.txnHashOfGrantedCollateral
                                                let requestApprovedByVerifiedRegistryBool = thisFundedContractObject.Item.requestApprovedByVerifiedRegistryBool
                                                let requestDeniedByVerifiedRegistryBool = thisFundedContractObject.Item.requestDeniedByVerifiedRegistryBool
                                                let requestTimestamp = thisFundedContractObject.Item.requestTimestamp

                                                API.put('api1169ba1e', '', { 
                                                    headers: { Authorization: `Bearer ${cognitoID}` },
                                                    body: { 
                                                        category: 'db',
                                                        tableName: 'contracts', 
                                                        primaryKey: thisFundedContract, 
                                                        requestOperation: 'get',
                                                    }
                                                }).then((thisContract) => {
                                                    let contractManager = thisContract.Item.managerUsername

                                                    API.put('api1169ba1e', '', { 
                                                        headers: { Authorization: `Bearer ${cognitoID}` },
                                                        body: { 
                                                        category: 'db',
                                                        tableName: 'users', 
                                                        primaryKey: String(currentUser), 
                                                        requestOperation: 'get',
                                                        }
                                                    }).then((userData) => {

                                                        let managerFirstName = userData.Item.firstName
                                                        managerFirstName = `${managerFirstName.substring(0,1).toUpperCase()}${managerFirstName.substring(1,managerFirstName.length).toLowerCase()}`

                                                        let managerEmailAddress = userData.Item.emailAddress

                                                        console.log('txnHashOfGrantedCollateral', contractManager, txnHashOfGrantedCollateral)

                                                        if(!requestDeniedByVerifiedRegistryBool && !requestApprovedByVerifiedRegistryBool && !collateralGrantedBool && !isNaN(requestedCollateralValueWei)){
                                                            contractMapObjectArray.push({
                                                                unformatted_requestedCollateralValueUSD,
                                                                requestedCollateralValueUSD,
                                                                collateralGrantedBool,
                                                                txnHashOfGrantedCollateral,
                                                                requestApprovedByVerifiedRegistryBool,
                                                                requestDeniedByVerifiedRegistryBool,
                                                                requestTimestamp,
                                                                contractManager,
                                                                managerFirstName,
                                                                managerEmailAddress,
                                                                contractAddress: thisFundedContract
                                                            })
                                                            if(contractMapObjectArray.length + deniedOrCompletedContractRequestsCount == requestedCollateralAssistanceContracts.length){
                                                                that.setState({
                                                                    contractMapObjectArray,
                                                                    showLoaderOverlay: false
                                                                })
                                                            }

                                                        } else {
                                                            that.setState({
                                                                contractMapObjectArray,
                                                                showLoaderOverlay: false
                                                            })
                                                            deniedOrCompletedContractRequestsCount += 1
                                                            if(contractMapObjectArray.length + deniedOrCompletedContractRequestsCount == requestedCollateralAssistanceContracts.length){
                                                                that.setState({
                                                                    contractMapObjectArray,
                                                                    showLoaderOverlay: false
                                                                })
                                                            }
                                                        }
                                                    })
                                                }) 
                                            } else {
                                                deniedOrCompletedContractRequestsCount += 1
                                                if(contractMapObjectArray.length + deniedOrCompletedContractRequestsCount == requestedCollateralAssistanceContracts.length){
                                                    that.setState({
                                                        contractMapObjectArray,
                                                        showLoaderOverlay: false
                                                    })
                                                }
                                            }   

                                        })    
                                    }
                                
                                })    
                            })
                        })
                    })
            });
        });
    }

            
    sendEmailFunction(subjectString, addresseeUsername, addresseeFirstName, recipientEmail, dispersedCollateralValueUSD, acceptanceNotifBool){

        var mailContent_signer = mimemessage.factory({contentType: 'multipart/mixed',body: []});
        mailContent_signer.header('From', 'The Blender Platform <theblenderplatform@lenderblock.io>');
        mailContent_signer.header('To', recipientEmail);
        mailContent_signer.header('Subject', subjectString);

        function emailBodyGeneratorDenial(addresseeFirstName, addresseeUsername){
            return (
               mimemessage.factory({
                  contentType: 'text/html;charset=utf-8',
                  body: String(`
                  <table border="0" cellpadding="50" cellspacing="0" width="100%" bgcolor="#2f3061" style="background: rgba(255, 255, 255, 1);">
                  <div>
                  <div style="text-align: center;"><img src="https://lenderblockpublicicons.s3.amazonaws.com/The+Blender+Platform.png" alt="TBP&quot;" width="450" height="112.5" /></div>
                  <div>&nbsp;</div>
                  <div style="text-align: right;">` + addresseeUsername.toUpperCase() + `</div>
                  <div><strong>Hello ` + addresseeFirstName + `,</strong></div>
                  <div>Username:` +  addresseeUsername.toUpperCase() + `,</div>
                  <div>&nbsp;</div>
                  <div>
                  The Blender Platform Borrower Collateral Assistance Program has denied your Request for Collateral Assistance.
                  </div>
                  <div>&nbsp;</div>
                  <div>
                  Denials can occur due to: 
                  </div>
                  <div>&nbsp;</div>
                  <div>
                  - Corporate Disbursement Limits
                  </div>
                  <div>
                  - Excessive Member Collateral Assistance Requests
                  </div>
                  <div>
                  - Insufficient or Suspicious Data within your Verified Registry Application
                  </div>
                  <div>
                  - Other Causes
                  </div>
                  <div>&nbsp;</div>
                  <div>
                  For more information and closure, please contact the Verified Registry Application Review Team within The Blender Platform Application.
                  </div>
                  </div>
                  </table>
                  `)
               })
            );
        }

        let bodyStringHTML = emailBodyGeneratorDenial(addresseeFirstName, addresseeUsername)

        var alternateEntity_signer = mimemessage.factory({
           contentType: 'multipart/alternate',
           body: []
        });

        var plainEntity_signer = bodyStringHTML;
     
        alternateEntity_signer.body.push(plainEntity_signer);
        
        mailContent_signer.body.push(alternateEntity_signer);

        var ses = new AWS.SES();

        ses.sendRawEmail({
           Destinations: [recipientEmail],
           RawMessage: {
              Data: mailContent_signer.toString() 
           },
           Source: 'theblenderplatform@lenderblock.io', // Must be verified within AWS SE
        }, (res) => {

        })

    }

    issueCollateralToContract(contractAddress, finalGivenCollateralUSD, managerUsername, managerFirstName, managerEmailAddress){
        window.scrollTo(0, 0)
        that.setState({
            showLoaderOverlay: true
        })

        let finalGivenCollateralWei = Number(finalGivenCollateralUSD) * 1000000000000000000

        API.put('api1169ba1e', '', { 
            headers: { Authorization: `Bearer ${cognitoID}` },
            body: { 
                category: 'db',
                tableName: 'verifiedRegistryFundedContracts', 
                primaryKey: contractAddress,
                requestOperation: 'update',
                updateExpression: 
                    `SET 
                        requestDeniedByVerifiedRegistryBool = :requestDeniedByVerifiedRegistryBool,
                        requestApprovedByVerifiedRegistryBool = :requestApprovedByVerifiedRegistryBool,
                        finalGivenCollateralWei = :finalGivenCollateralWei
                    `,
                expressionAttributeValues: { 
                    ':requestDeniedByVerifiedRegistryBool': false,
                    ':requestApprovedByVerifiedRegistryBool': true,
                    ':finalGivenCollateralWei': finalGivenCollateralWei,
                }
            }

        }).then((res) => {
            API.put('api1169ba1e', '', { 
                headers: { Authorization: `Bearer ${cognitoID}` },
                body: { 
                    category: 'db',
                    tableName: 'general', 
                    primaryKey: 'adminApprovedPendingCollateralDisbursements', 
                    requestOperation: 'get',
                }
            }).then((adminApprovedPending) => {
                let adminApprovedPendingCollateralDisbursements = adminApprovedPending.Item.adminApprovedPendingCollateralDisbursements

                if(!adminApprovedPendingCollateralDisbursements.includes(contractAddress)){
                    API.put('api1169ba1e', '', { 
                        headers: { Authorization: `Bearer ${cognitoID}` },
                        body: { 
                            category: 'db',
                            tableName: 'general', 
                            primaryKey: 'adminApprovedPendingCollateralDisbursements', 
                            requestOperation: 'update',
                            updateExpression: `SET 
                                adminApprovedPendingCollateralDisbursements = list_append(adminApprovedPendingCollateralDisbursements, :contractAddress)
                            `, 
                            expressionAttributeValues: { 
                                ':contractAddress': [contractAddress],
                            }
                        }
                    }).then((res) => {
                    
                        that.initialMountComponents()
                    })
                } else {
                    that.initialMountComponents()
                }
            })
        })
    }


    rejectCollateralAssistanceRequest(contractAddress, managerUsername, managerFirstName, managerEmailAddress){
        window.scrollTo(0, 0)
        that.setState({
            showLoaderOverlay: true
        })
        API.put('api1169ba1e', '', { 
            headers: { Authorization: `Bearer ${cognitoID}` },
            body: { 
                category: 'db',
                tableName: 'verifiedRegistryFundedContracts', 
                primaryKey: contractAddress,
                requestOperation: 'update',
                updateExpression: 
                    `SET 
                        requestApprovedByVerifiedRegistryBool = :requestApprovedByVerifiedRegistryBool,
                        requestDeniedByVerifiedRegistryBool = :requestDeniedByVerifiedRegistryBool,
                        collateralGrantedBool = :collateralGrantedBool
                    `,
                expressionAttributeValues: { 
                    ':requestDeniedByVerifiedRegistryBool': true,
                    ':requestApprovedByVerifiedRegistryBool': false,
                    ':collateralGrantedBool': false
                }
            }
        })


        let globalDateString = String(new Date()).substr(4,11)
        globalDateString = globalDateString.substr(0,3) + '.' + globalDateString.substr(3,3) + ',' + globalDateString.substr(6,5);

        let subjectString = 'Collateral Assistance Disbursement'
        let acceptanceNotifBool = false;

        that.initialMountComponents()
        that.sendEmailFunction(subjectString, managerUsername.toUpperCase(), managerFirstName, managerEmailAddress, 0, acceptanceNotifBool)          
    }

    render(){
        
        const { touched } = this.state;
        const className = touched ? 'button1_touched' : 'button1';


        applicantUsername = localStorage.getItem('applicantUsername')

        if(navigationHandler(that.state.navigationDestinationObject)){
            return navigationHandler(that.state.navigationDestinationObject)
        }

        return (
        <div className="CollateralAssistance">

            <React.Fragment>
                {
                    that.state.showLoaderOverlay
                    ?
                    <div
                        style={{
                            position: 'absolute',
                            // top: '2vh',
                            // right: '2vw',
                            // left: '2vw',
                            width: '100vw',
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                            padding: 10,
                            zIndex: 2000,
                        }}
                    >
                        <LoadingIcons.BallTriangle 
                            style={{
                                marginTop: '45vh',
                                marginBottom: '45vh',
                                marginRight: '42vw',
                                marginLeft: '42vw',
                            }} 
                            strokeWidth={1}
                            stroke="rgba(255, 255, 255, 1)" 
                            fill="rgba(0, 158, 129, 1)" 
                        />
                    </div>
                    :
                    null
                }
            </React.Fragment>
            <React.Fragment>
                {
                    that.state.insufficientFundsOverlay
                    ?
                    <div
                        style={{
                            position: 'absolute',
                            // top: '2vh',
                            // right: '2vw',
                            // left: '2vw',
                            width: '100vw',
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                            padding: 10,
                            zIndex: 2000,
                        }}
                    >
                        <div 
                            style={{
                                marginTop: '45vh',
                                marginBottom: '45vh',
                                marginRight: '42vw',
                                marginLeft: '42vw',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                borderRadius: 2,
                                borderColor: 'rgba(204, 12, 12, 1)',
                                borderStyle: 'solid',
                                borderWidth: 2,
                            }} 
                            // strokeWidth={1}
                            // stroke="rgba(255, 255, 255, 1)" 
                            // fill="rgba(0, 158, 129, 1)" 
                            // height={'10vh'} width={'16vw'} 
                        >
                        <p 
                            style={{ 
                                textAlign: 'center',
                                color: 'rgba(204, 12, 12, 1)',
                                fontSize: 18,
                                fontWeight: '600',
                            }} 
                            className="logoText"
                        >
                               {'Insufficient Remaining Disbursement Budget'}
                        </p>
                        </div>
                    </div>
                    :
                    null
                }
            </React.Fragment>


            <div className="headerRowBox"> 
                <div 
                    onClick={() => {
                      that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/home',
                        }
                      }) 
                    }}
                >
                    <div className="logoBox"> 
                        <img className="logoImage" src="https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png"></img>
                        <p className="logoText"><b>THE BLENDER PLATFORM</b></p>
                    </div>
                </div>
                <div className="headerRowBoxNavBar">
                    
                  
                </div>
                <div className="headerUsernameBox"> 
                    <p style={{ textAlign: 'right', marginTop: 12.5, }} className="logoText"><b>
                        {/* <span style={{ textAlign: 'right', color: 'rgba(28, 28, 54, 1)' }}>
                            {'User Admin\n'}
                        </span> */}
                        {String(currentUser).toUpperCase()}
                    </b></p>
                    <Button
                        className='buttonStyle'
                        style={{
                        padding: 10,
                        marginLeft: 25
                        }} 
                        onMouseDown={this.toggleTouched} 
                        onMouseUp={this.handleMouseUp} 
                        onClick={() => { 
                            Auth.signOut()
                            that.setState({
                                navigationDestinationObject: {
                                    navigationRoute: '/login',
                                }
                            }) 
                        }}
                    >
                        Sign Out
                    </Button>
                </div>
            </div>
            <div 
                style={{
                    backgroundColor:'#282c34',
                    paddingTop: 235,
                    paddingBottom: 50,
                    paddingLeft: 20,
                    width: '100%',
                    minHeight: '100vh',
                    height: '100%',
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    /* align-items: center;
                    justify-content: center; */
                }}
            >
                <p 
                    style={{ 
                        position: 'absolute', 
                        top: 135, 
                        left: 25, 
                        color: 'rgba(0, 158, 129, 1)', 
                        fontSize: 18, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                        textUnderlineOffset: 10, 
                        textDecorationLine: 'underline', 
                        textDecorationColor: 'rgba(0, 158, 129, 1)'
                    }}
                >
                    Administrator Center
                    <span 
                        style={{ 
                            textAlign: 'left', 
                            fontSize: 16, 
                            fontWeight: '500', 
                            color: 'rgba(255, 255, 255, 1)', 
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'Collateral Assistance Management'}
                    </span>
                </p>
                <Button
                    className='buttonStyle'
                    style={{
                    // height: 100,
                    padding: 10,
                    marginLeft: 25,
                    position: 'absolute', 
                    top: 205, 
                    left: 0, 
                    }} 
                    onMouseDown={this.toggleTouched} 
                    onMouseUp={this.handleMouseUp} 
                    onClick={() => {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/home',
                            }
                        }) 
                    }}
                >
                    {'<<'} &nbsp; {'Administrator Center Home'}
                </Button>
                <div
                    style={{
                        width: '95vw',
                        // height: 150,
                        borderRadius: 3,
                        borderWidth: 0.5,
                        paddingRight: 15,
                        // paddingLeft: 25,
                        // borderStyle: 'solid',
                        // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                        borderColor: 'rgba(0, 158, 129, 1)',
                        justifyContent: 'space-between',
                        alignItems: 'self-start',
                        position: 'relative', 
                        //marginTop: 235, 
                        left: 25, 
                        flexDirection: 'row',
                        display: 'flex',
                    }}
                >
                    <React.Fragment>
                        <ul
                             style={{
                                listStyleType: 'none',
                                padding: 0,
                                left: 25, 
                                marginRight: 100,
                                width: '40vw',
                                // height: 500,
                                // borderStyle: 'solid',
                                // borderRadius: 3,
                                // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                // borderColor: 'rgba(0, 158, 129, 1)',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <p 
                                style={{ 
                                    // position: 'absolute', 
                                    marginTop: 20, 
                                    marginLeft: 0, 
                                    color: 'rgba(255, 255, 255, 1)', 
                                    fontSize: 17, 
                                    fontWeight: '600', 
                                    textAlign: 'left',
                                    textShadowColor: 'rgba(255, 255, 255, 1)',
                                    textShadowOffset: {width: 10, height: 10},
                                    textShadowRadius: 10,  
                                }}
                            >
                                {`All Pending Collateral Disbursements (${that.state.contractMapObjectArray.length})`}
                            </p>
                            {that.state.contractMapObjectArray.map(listitem => (

                                <li
                                    key={listitem.requestTimestamp}
                                    style={{
                                        // width: '45vw',
                                        // height: 150,
                                        borderRadius: 3,
                                        borderWidth: 0.5,
                                        borderStyle: 'solid',
                                        backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                        borderColor: 'rgba(0, 158, 129, 1)',
                                        // justifyContent: 'center',
                                        // alignItems: 'center',
                                        paddingLeft: 5, 
                                        marginTop: 20,
                                    }}
                                    onClick={() => {
                                        that.setState({
                                            selectedContractRequest: [listitem],
                                            unformatted_requestedCollateralValueUSD: listitem.unformatted_requestedCollateralValueUSD,
                                        }) 
                                    }}
                                >
                                    <div>
                                        <p 
                                            style={{ 
                                                textAlign: 'left',
                                                fontSize: 14, 
                                                fontWeight: '500', 
                                                color: 'rgba(255, 255, 255, 1)', 
                                                marginTop: 10,
                                                marginLeft: 5,
                                            }}
                                        ><b>
                                            {`${listitem.contractManager.toUpperCase()}`}
                                        </b></p>
                                        <p 
                                            style={{ 
                                                textAlign: 'left',
                                                fontSize: 14, 
                                                fontWeight: '500', 
                                                color: 'rgba(255, 255, 255, 1)', 
                                                marginTop: 15,
                                                marginLeft: 5,
                                                // textUnderlineOffset: 3, 
                                                // textDecorationLine: 'underline', 
                                                // textDecorationColor: 'rgba(255, 255, 255, 1)',
                                            }}
                                        >
                                            Requested Collateral Assistance: 
                                        </p>
                                        <p 
                                            style={{ 
                                                textAlign: 'left',
                                                fontSize: 14, 
                                                fontWeight: '500', 
                                                color: 'rgba(255, 255, 255, 1)', 
                                                marginTop: 15,
                                                marginBottom: 10,
                                                marginLeft: 5,
                                                // textUnderlineOffset: 3, 
                                                // textDecorationLine: 'underline', 
                                                // textDecorationColor: 'rgba(255, 255, 255, 1)',
                                            }}
                                        >
                                            {`${listitem.requestedCollateralValueUSD}`}
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </React.Fragment>
                    <React.Fragment>
                      <ul
                         style={{
                            listStyleType: 'none',
                            padding: 0,
                            // position: 'absolute', 
                            top: 265, 
                            left: 25, 
                            marginRight: '5vw',
                            // width: '45vw',
                            // height: 500,
                            // borderStyle: 'solid',
                            // borderRadius: 3,
                            // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                            // borderColor: 'rgba(0, 158, 129, 1)',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                      >
                       
                        {that.state.selectedContractRequest.map(listitem => (
                            
                            <li
                                key={listitem.requestTimestamp}
                                style={{
                                    // width: '45vw',
                                    // height: 150,
                                    // borderRadius: 3,
                                    textAlign: 'left',
                                    // paddingBottom: 20,
                                    // borderTopWidth: 0,
                                    // borderLeftWidth: 0,
                                    // borderRightWidth: 0,
                                    // borderBottomWidth: 0.1,
                                    // borderStyle: 'solid',
                                    // borderColor: 'rgba(0, 158, 129, 1)',
                                    // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    marginBottom: 20,
                                }}
                            >
                                <p 
                                    style={{ 
                                        textAlign: 'left',
                                        fontSize: 17, 
                                        fontWeight: '500', 
                                        color: 'rgba(255, 255, 255, 1)', 
                                        marginTop: 20,
                                        marginLeft: 0,
                                        marginRight: 10,
                                    }}
                                >
                                    <b>
                                        {`Approve Collateral Value Disbursement to Member Credit-Line`}
                                    </b>
                                </p>
                                <p 
                                    style={{ 
                                        textAlign: 'left',
                                        fontSize: 14, 
                                        fontWeight: '500', 
                                        color: 'rgba(255, 255, 255, 1)', 
                                        marginTop: 10,
                                        marginLeft: 0,
                                        marginRight: 10,
                                    }}
                                >
                                    <b>
                                        {`Member Recipient: ${listitem.contractManager.toUpperCase()}`}
                                    </b>
                                </p>
                                {/* <p 
                                    style={{ 
                                        textAlign: 'left',
                                        fontSize: 14, 
                                        fontWeight: '500', 
                                        color: 'rgba(247, 90, 90, 1)', 
                                        marginTop: 10,
                                        marginLeft: 0,
                                        marginRight: 10,
                                    }}
                                >
                                    <b>
                                        {`Remaining Disbursement Budget: ${Number(that.state.contractBalanceUSD).toFixed(2)} USD`}
                                    </b>
                                </p> */}
                                <p 
                                    style={{ 
                                        textAlign: 'left',
                                        fontSize: 14, 
                                        fontWeight: '500', 
                                        color: 'rgba(0, 158, 129, 1)', 
                                        marginTop: 10,
                                        marginLeft: 0,
                                        marginRight: 10,
                                    }}
                                >
                                    <b>
                                        {`Collateral Max Limit Disbursement Value: ${Number(that.state.baseStandardCollateralAssistanceDisbursementValueUSD).toFixed(2)} USD`}
                                    </b>
                                </p>
                                <div
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        marginTop: 5,
                                    }}
                                >
                                    <input 
                                        type="text" 
                                        id="text" 
                                        // className="loginInput"  
                                        readOnly
                                        value={Number(listitem.unformatted_requestedCollateralValueUSD) >= Number(that.state.baseStandardCollateralAssistanceDisbursementValueUSD) ? Number(that.state.baseStandardCollateralAssistanceDisbursementValueUSD).toFixed(2) : Number(listitem.unformatted_requestedCollateralValueUSD).toFixed(2)}
                                        // onChange={(event) => {
                                        //     that.setState({
                                        //         givenCollateralAssistanceValueUSD: event.target.value
                                        //     })
                                        // }} 
                                        name="text"
                                        // placeholder='Leave Reviewer Note '
                                        style={{
                                            fontWeight: '600',
                                            fontFamily: 'arial',
                                            color: 'rgba(0, 0, 0, 1)',
                                            padding: 5,
                                            textAlign: 'left',
                                            textAlignVertical: 'center',
                                            fontSize: 15,
                                            width: '5vw',
                                            height: 20,
                                            borderRadius: 2,
                                            borderWidth: 1,
                                            borderColor: 'rgba(0, 158, 129, 1)',
                                            borderStyle: 'solid',
                                        }}
                                    />
                                    <input 
                                        type="text" 
                                        id="text" 
                                        // className="loginInput"  
                                        readOnly
                                        value={` USD`}
                                        
                                        name="text"
                                        // placeholder='Leave Reviewer Note '
                                        style={{
                                            fontWeight: '600',
                                            fontFamily: 'arial',
                                            color: 'rgba(0, 158, 129, 1)',
                                            padding: 5,
                                            textAlign: 'left',
                                            textAlignVertical: 'center',
                                            fontSize: 13,
                                            width: '5vw',
                                            height: 20,
                                            borderRadius: 2,
                                            borderWidth: 0.1,
                                            backgroundColor: 'rgba(255, 255, 255, 0)',
                                            borderColor: 'rgba(255, 255, 255, 0)',
                                            borderStyle: 'solid',
                                        }}
                                    />
                                   
                                </div>
                                <div
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        marginTop: 15,
                                    }}
                                >
                                    <Button 
                                        className='buttonStyle green'
                                        style={{
                                            padding: 5,
                                            marginTop: 5,
                                            marginRight: 10,
                                        }} 
                                        onMouseDown={this.toggleTouched} 
                                        onMouseUp={this.handleMouseUp} 
                                        onClick={() => { 
                                            let finalGivenCollateralUSD;
                                            if(Number(listitem.unformatted_requestedCollateralValueUSD) >= Number(that.state.baseStandardCollateralAssistanceDisbursementValueUSD)){
                                                finalGivenCollateralUSD = Number(that.state.baseStandardCollateralAssistanceDisbursementValueUSD) 
                                            } else {
                                                finalGivenCollateralUSD = Number(listitem.unformatted_requestedCollateralValueUSD)
                                            }
                                            // if(Number(finalGivenCollateralUSD) > Number(that.state.contractBalanceUSD) + 1){
                                            //     that.setState({
                                            //         insufficientFundsOverlay: true
                                            //     })

                                            //     setTimeout(() => {
                                            //         that.setState({
                                            //             insufficientFundsOverlay: false 
                                            //         })
                                            //     }, 5000)
                                               
                                            // } else {
                                                
                                                that.issueCollateralToContract(listitem.contractAddress, finalGivenCollateralUSD, listitem.contractManager.toUpperCase(), listitem.managerFirstName, listitem.managerEmailAddress)
                                            // }
                                        }}
                                    > 
                                        Disburse Collateral Funds 
                                    </Button>
                                    <Button 
                                        className='buttonStyle red'
                                        style={{
                                            padding: 5,
                                            marginTop: 5,
                                            marginLeft: 10,
                                        }} 
                                        onMouseDown={this.toggleTouched} 
                                        onMouseUp={this.handleMouseUp} 
                                        onClick={() => { 
                                            
                                            that.rejectCollateralAssistanceRequest(listitem.contractAddress, listitem.contractManager.toUpperCase(), listitem.managerFirstName, listitem.managerEmailAddress)
                                        }}
                                    > 
                                        Deny Collateral Assistance Request
                                    </Button>
                                </div>
                            
                            </li>
                        ))}
                      </ul>
                    </React.Fragment>
                </div>
            </div>
        
        </div>
        );
    }

}

export default CollateralAssistance;
