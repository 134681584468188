import Amplify, { Auth, Storage, API } from 'aws-amplify'
import './VR_ApplicationShow.css'; // Tell webpack that Button.js uses these styles
import React, {Component, Fragment, useRef} from 'react';
import awsconfig from '../aws-exports.js';
import { navigationHandler, routeProps } from '../utilities/navigator.js';
import LoadingIcons from 'react-loading-icons'
import {Buffer} from 'buffer';
import { tokenLifeTimeLimit, checkValidAuthTokenIntervalPeriod_ms,  LENDERBLOCKADMINWHITELIST} from '../blenderData/securityConfigurations';

import 'semantic-ui-css/semantic.min.css' 
import { Button, Modal, Header, Image, } from 'semantic-ui-react'


import axios from 'axios';
const querystring = require('querystring');

var AWS = require('aws-sdk');

var mimemessage = require('mimemessage');

Amplify.configure(awsconfig);
Auth.configure(awsconfig);
API.configure(awsconfig);

var checkValidAuthTokenInterval;

var cognitoID;
var currentUser;
var currentUserCap;
var stripeAccountID;
var stripeCustomerID;
var primaryDisplayCurrency;
var secondaryDisplayCurrency;
var publicKey;
var corporateBackedUsernamesArray;

let that;

let applicantUsername;

let monthLengtheningEnum = {
    JAN: 'January',
    FEB: 'February',
    MAR: 'March',
    APR: 'April',
    MAY: 'May',
    JUN: 'June',
    JUL: 'July',
    AUG: 'August',
    SEP: 'September',
    OCT: 'October',
    NOV: 'November',
    DEC: 'December',
}

class VR_ApplicationShow extends Component {

    constructor() {
        super()

        this.state = {
            usernameText: '',
            passwordText: '',
            touched: false,
            navigationDestinationObject: {
              navigationRoute: '',
            },
            button_recentApp_opacity: 1,
            button_pendingApp_opacity: 1,
            pendingApplicationsList: [],
            requestedAwaitingDocumentList: [],
            messageLineArray: [],
            submittedDocumentIDList: [],
            ableToFinalizeAndApproveBool: false,
            searchedUsername: '',
            applicantUserFirstName: null,
            showLoaderOverlay: false,
            successOverlay: false,
            overheadApplicationApprovalString: '',
            applicantUserFirstName: '',
            applicantEmailAddress: '',
            applicantUsername: '',
            searchedUser_foundUsername: '',
            searchedUser_fullName: '',
            searchUser_foundBool: false,
            file: null,
            setFile: null,
            uploadedFileURL: null, 
            setUploadedFileURL: null,
            currentUser: null,
            didFileUpload: false,
        };
        that = this

    }

    checkValidAuthToken(){

      let navigationDestinationObject = {
        navigationRoute: ''
      };
          
      Auth.currentAuthenticatedUser().then((res) => {
        if(res){

            currentUser = res.username
    
            if(!LENDERBLOCKADMINWHITELIST.includes(String(currentUser).toLowerCase())){
                Auth.signOut()
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                }) 
            }
        } else {
            Auth.signOut()
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login',
                },
            }) 
        }
    }).catch((err) => {
        Auth.signOut()
        that.setState({
            navigationDestinationObject: {
                navigationRoute: '/login',
            },
        }) 
    })
      
      
      checkValidAuthTokenInterval = setInterval(() => {
        Auth.currentAuthenticatedUser().then((res) => {
          if(res){
            let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
            let tokenLifetime = Date.now() - authTokenIssueTime
    
            if(tokenLifetime >= tokenLifeTimeLimit){
              that.setState({
                  navigationDestinationObject: {
                      navigationRoute: '/login'
                  }
              })
              if(navigationHandler(navigationDestinationObject)){
                return navigationHandler(navigationDestinationObject)
              }
            }
          } else {
              that.setState({
                  navigationDestinationObject: {
                      navigationRoute: '/login'
                  }
              })
              if(navigationHandler(navigationDestinationObject)){
                  return navigationHandler(navigationDestinationObject)
              }
          }
        }).catch(() => {
          that.setState({
              navigationDestinationObject: {
                  navigationRoute: '/login'
              }
          })
          if(navigationHandler(navigationDestinationObject)){
            return navigationHandler(navigationDestinationObject)
          }
        })
      }, checkValidAuthTokenIntervalPeriod_ms)
  }

  componentWillUnmount(){

  }
    componentDidMount(){
      that.checkValidAuthToken()
      
      that.initialMountComponents()

      that.setState({
        usernameText: '',
        passwordText: '',
        touched: false,
        navigationDestinationObject: {
          navigationRoute: '',
        },
        button_recentApp_opacity: 1,
        button_pendingApp_opacity: 1,
        pendingApplicationsList: [],
        requestedAwaitingDocumentList: [],
        messageLineArray: [],
        submittedDocumentIDList: [],
        ableToFinalizeAndApproveBool: false,
        searchedUsername: '',
        applicantUserFirstName: null,
        //showLoaderOverlay: true,
        successOverlay: false,
        overheadApplicationApprovalString: '',
        applicantUserFirstName: '',
        applicantEmailAddress: '',
        applicantUsername: '',
        file: null,
      })
      
      Auth.currentAuthenticatedUser().then((res) => {
          if(res){
            let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
            let tokenLifetime = Date.now() - authTokenIssueTime
            if(tokenLifetime < tokenLifeTimeLimit){
                currentUser = res.username
                cognitoID = res.signInUserSession.idToken.jwtToken
                        
                currentUserCap = currentUser.toUpperCase()
                if(currentUserCap.length > 10){
                    currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
                }
                
                that.setState({
                    currentUser,
                    navigationDestinationObject: {
                        navigationRoute: '',
                    },
                })
            } else {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                })
            }
          } else {
              that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login',
                },
              })
          }
      
      }).catch((err) => {
          that.setState({
            navigationDestinationObject: {
                navigationRoute: '/login',
            },
          })
      })
    }

    toggleTouched = () => {
        that.setState( prevState => ({
        touched: !prevState.touched
        }));
    }
    
    handleMouseUp = () => {
        // Handle smooth animation when clicking without holding
        setTimeout( () => {
        that.setState({ touched: false });
        }, 150);
    }



    initialMountComponents(){

        
        Auth.currentAuthenticatedUser().then((res) => {
            currentUser = res.username
            cognitoID = res.signInUserSession.idToken.jwtToken

            AWS.config.update({region:'us-east-1'});
                            
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: 'us-east-1:8973ceec-5da3-423f-b3c6-e31ebdc08ce8',
                Logins: { 
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_vS08WURWh': cognitoID,
                }
            })

            AWS.config.credentials.refresh((error) => {
                if (error) {
                    return error
                }
            });


            currentUserCap = currentUser.toUpperCase()
            if(currentUserCap.length > 10){
                currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
            }
            that.setState({ currentUser })
                        
            
        });
    }

    sendEmailFunction(subjectString, addresseeUsername, addresseeFirstName, recipientEmail, customBodyString){

      var mailContent_signer = mimemessage.factory({contentType: 'multipart/mixed',body: []});
      mailContent_signer.header('From', 'The Blender Platform <theblenderplatform@lenderblock.io>');
      mailContent_signer.header('To', recipientEmail);
      mailContent_signer.header('Subject', subjectString);

      let bodyStringHTML = String(`<div style="text-align: right;">` + addresseeUsername.toUpperCase() + `</div><div><strong>Hello ` + addresseeFirstName + `,</strong></div><div>&nbsp;</div><div>${customBodyString}</div><div>&nbsp;</div><div>Happy Lending and Borrowing!</div><div>The Blender Platform</div><div>&nbsp;</div><div><img src="https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png" alt="TBP&quot;" width="77" height="77" /></div><div>&nbsp;</div><div>&nbsp;</div>`)

      var alternateEntity_signer = mimemessage.factory({
         contentType: 'multipart/alternate',
         body: []
      });

      function emailBodyGenerator(){
          return (
              mimemessage.factory({
                  contentType: 'text/html;charset=utf-8',
                  body: bodyStringHTML
              })
          );
      }

      var plainEntity_signer = emailBodyGenerator()
   
      alternateEntity_signer.body.push(plainEntity_signer);
      
      mailContent_signer.body.push(alternateEntity_signer);

      var ses = new AWS.SES();

      ses.sendRawEmail({
         Destinations: [recipientEmail],
         RawMessage: {
            Data: mailContent_signer.toString() 
         },
         Source: 'theblenderplatform@lenderblock.io', // Must be verified within AWS SE
      }, (res) => {

      })
    }

    addMemberToVerifiedRegistry(){
      window.scrollTo(0, 0)
      that.setState({
        showLoaderOverlay: true
      })
      
      API.put('api1169ba1e', '', { 
        headers: { Authorization: `Bearer ${cognitoID}` },
        body: { 
            category: 'db',
            tableName: 'verifiedRegistryApplications', 
            primaryKey: applicantUsername, 
            requestOperation: 'update',
            updateExpression: `SET 
              applicationStatus = :applicationStatus
            `, 
            expressionAttributeValues: { 
              ':applicationStatus': 'activated'
            }
        }
      }).then((res) => {
        that.initialMountComponents()

        let globalDateString = String(new Date()).substr(4,11)
        globalDateString = globalDateString.substr(0,3) + '.' + globalDateString.substr(3,3) + ',' + globalDateString.substr(6,5);
  
        let subjectString = 'Verified Registry Status - Acceptance Confirmed'
        let emailBodyString = String(
`<div>
<div>
The Blender Platform Verified Registry Review Team has approved and accepted your Verified Registry Application and all documents which you have submitted for verification.
</div>
<div>&nbsp;</div>
<div>
You may now Request Blender Collateral Assistance within Credit-Lines you create within The Blender Platform, which (if granted) serves to increase the Credit-Limit of the corresponding Credit-Lines for your increased borrowing volume. 
</div>
<div>&nbsp;</div>
<div>
Important Notices: 
</div>
<div>&nbsp;</div>
<div>
- Acceptance to the Verified Registry does not guarantee Collateral Assistance Disbursement.
</div>
<div>
- The Verified Registry Review Team may request more information from you over time to continually maintain your acceptance. 
</div>
<div>
- Information collected via the Verified Registry may be utilized in debt collection efforts in the ill-case of your delinquency or failure to repay owed funds to a Credit-Line in which you are the borrower.
</div>
<div>&nbsp;</div>
<div>
Visit your Credit-Lines within The Blender Platform to begin your request for Collateral Assistance.
</div>
</div>
`
        )
  
        that.sendEmailFunction(subjectString, that.state.applicantUsername.toUpperCase(), that.state.applicantUserFirstName, that.state.applicantEmailAddress, emailBodyString)          
      })
    }

    expelMemberFromVerifiedRegistry(){
      window.scrollTo(0, 0)
      that.setState({
        showLoaderOverlay: true
      })

      API.put('api1169ba1e', '', { 
        headers: { Authorization: `Bearer ${cognitoID}` },
        body: { 
            category: 'db',
            tableName: 'verifiedRegistryApplications', 
            primaryKey: applicantUsername, 
            requestOperation: 'update',
            updateExpression: `SET 
              applicationStatus = :applicationStatus
            `, 
            expressionAttributeValues: { 
              ':applicationStatus': 'unactivated'
            }
        }
      }).then((res) => {
        that.initialMountComponents()

        let globalDateString = String(new Date()).substr(4,11)
        globalDateString = globalDateString.substr(0,3) + '.' + globalDateString.substr(3,3) + ',' + globalDateString.substr(6,5);

        let subjectString = 'Verified Registry Status - Revocation Alert'
        let emailBodyString = String(
`<div>
<div>
The Blender Platform Verified Registry Review Team has revoked your Verified Registry Acceptance.
</div>
<div>&nbsp;</div>
<div>
Revocations can occur due to:  
</div>
<div>&nbsp;</div>
<div>
- Newly Required or Outdated Documents Submissions
</div>
<div>
- Excessive Member Collateral Assistance Requests
</div>
<div>
- Insufficient or Suspicious Data within your Verified Registry Application
</div>
<div>
- Other Causes
</div>
<div>&nbsp;</div>
<div>
For more information and closure, please contact the Verified Registry Application Review Team within The Blender Platform Application.
</div>
</div>
`
        )

        that.sendEmailFunction(subjectString, that.state.applicantUsername.toUpperCase(), that.state.applicantUserFirstName, that.state.applicantEmailAddress, emailBodyString)       
      })   
    }


    uploadFilesToVR_foundUsername(foundUsername){
      
    }

    queryUsername(){
      
      if(String(that.state.searchedUsername).length > 1){
        API.put('api1169ba1e', '', { 
          headers: { Authorization: `Bearer ${cognitoID}` },
          body: { 
          category: 'db',
          tableName: 'users', 
          primaryKey: String(that.state.searchedUsername).toLowerCase(), 
          requestOperation: 'get',
          }
        }).then((userData) => {
          if(userData.hasOwnProperty('Item')){
            let searchedUser_firstName = userData.Item.firstName
            let searchedUser_lastName = userData.Item.lastName
            let searchedUser_fullName = searchedUser_firstName.substring(0,1).toUpperCase()+searchedUser_firstName.substring(1,searchedUser_firstName.length).toLowerCase() + ' ' + searchedUser_lastName.substring(0,1).toUpperCase()+searchedUser_lastName.substring(1,searchedUser_lastName.length).toLowerCase()

            that.setState({
              searchedUser_fullName,
              searchUser_foundBool: true,
              searchedUser_foundUsername: String(that.state.searchedUsername).toLowerCase()
            })
          } else {
            that.setState({
              searchedUser_fullName: '',
              searchUser_foundBool: false
            })
          }
        })

      } else {
        that.setState({
          searchedUser_fullName: '',
          searchUser_foundBool: false
        })
      }
    }

    handleChange(event){
      if(event.target.files){
        if(event.target.files.length > 0){
          that.setState({
            file: event.target.files[0]
          })
          
        }
      }
    }

    handleSubmit(event) {
      event.preventDefault()

      let idCardBase64 = '';
      let fileType = '';
      that.getBase64(that.state.file, (result) => {
        idCardBase64 = result;

        loopBase64(0)
        function loopBase64(v){
          if(v<=idCardBase64.length-1){
            if(idCardBase64[v]==';'){
              fileType = idCardBase64.substring(5,v)
              idCardBase64 = idCardBase64.substring(v+8, idCardBase64.length-1)
              that.uploadToDB(idCardBase64, that.state.searchedUser_foundUsername, 'fileNameTester', fileType)
            } else {
              loopBase64(v+1)
            }
          } 
        }

      });
  
    }

    getBase64(file, cb) {
      let reader = new FileReader();
      reader.readAsDataURL(that.state.file);
      reader.onload = function () {
          cb(reader.result)
      };
      reader.onerror = function (error) {
      };
    }

    uploadToDB(idCardBase64, applicantUsername, fileName, fileType){

      API.put('triggerVerifiedRegistryUpload', '', { 
        headers: { 
          "Authorization": `Bearer ${cognitoID}`,
          // "Access-Control-Allow-Origin": "*", // Required for CORS support to work 
        //   "Access-Control-Allow-Credentials": "true", // Required for cookies, aut
        //   "Access-Control-Allow-Methods": 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
        //   "Content-Type": "application/json"
        },
        body: { 
            "fileName": fileName,
            "base64": idCardBase64, 
            "username": applicantUsername, 
            "fileType": fileType
        }
      }).then((res) => {
        if(res.status == 200){
          that.setState({
            successOverlay: true
          })
          this.setState({
            didFileUpload: true
          })

          setTimeout(() => {
            that.setState({
              successOverlay: false
            })
          }, 2500)
        }
      })
    }

    formatNewDateObject(documentSubmissionTimestamp){

      documentSubmissionTimestamp = String(documentSubmissionTimestamp)

      let clockTimeString = `${Number(String(documentSubmissionTimestamp).substr(16,2)) <= 12 ? Number(String(documentSubmissionTimestamp).substr(16,2)) : Number(String(Number(String(documentSubmissionTimestamp).substr(16,2)) - 12))}${String(documentSubmissionTimestamp).substr(18,3)} ${Number(String(documentSubmissionTimestamp).substr(16,2)) < 12 ? 'AM' : 'PM'}`

      documentSubmissionTimestamp = clockTimeString + ' - ' + String(documentSubmissionTimestamp.substring(4,7)) + '.' + String(documentSubmissionTimestamp.substring(7,10)) + ',' + String(documentSubmissionTimestamp.substring(10,15))
      return documentSubmissionTimestamp;
    }

    render(){
        applicantUsername = localStorage.getItem('applicantUsername')
     
        const { touched } = that.state;
        const className = touched ? 'button1_touched' : 'button1';


        if(navigationHandler(that.state.navigationDestinationObject)){
            return navigationHandler(that.state.navigationDestinationObject)
        }

        return (
        <div className="VR_ApplicationShow">
            <React.Fragment>
              {
                  that.state.showLoaderOverlay
                  ?
                  <div
                      style={{
                          position: 'absolute',
                          // top: '2vh',
                          // right: '2vw',
                          // left: '2vw',
                          width: '100vw',
                          height: '100vh',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(255, 255, 255, 0.25)',
                          padding: 10,
                          zIndex: 2000,
                      }}
                  >
                      <LoadingIcons.BallTriangle 
                          style={{
                              marginTop: '45vh',
                              marginBottom: '45vh',
                              marginRight: '42vw',
                              marginLeft: '42vw',
                          }} 
                          strokeWidth={1}
                          stroke="rgba(255, 255, 255, 1)" 
                          fill="rgba(0, 158, 129, 1)" 
                           
                      />
                  </div>
                  :
                  null
              }
            </React.Fragment>

            <Modal
              // onClose={() => setOpen(false)}
              // onOpen={() => setOpen(true)}
              open={that.state.successOverlay}
              // trigger={<Button>Show Modal</Button>}
            >
              <Modal.Header>UPLOADED!</Modal.Header>
              <Modal.Content image>
                <Image size='small' src='https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png' wrapped />
                <Modal.Description>
                  <Header>View this File under the Verified Registry Application  -  {String(that.state.searchedUser_foundUsername).toUpperCase()}</Header>
                </Modal.Description>
              </Modal.Content>
              {/* <Modal.Actions>
                <Button color='black' onClick={() => setOpen(false)}>
                  Nope
                </Button>
                <Button
                  content="Yep, that's me"
                  labelPosition='right'
                  icon='checkmark'
                  onClick={() => setOpen(false)}
                  positive
                />
              </Modal.Actions> */}
            </Modal>

            {/* <React.Fragment>
              {
                  that.state.successOverlay
                  ?
                  <div
                      style={{
                          position: 'absolute',
                          // top: '2vh',
                          // right: '2vw',
                          // left: '2vw',
                          width: '100vw',
                          height: '100vh',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(255, 255, 255, 0.25)',
                          padding: 10,
                          zIndex: 2000,
                      }}
                  >
                    <p 
                      style={{ 
                          // position: 'absolute', 
                          marginTop: '45vh',
                          marginBottom: '45vh',
                          marginRight: '42vw',
                          marginLeft: '42vw',
                          top: 5, 
                          color: 'rgba(255, 255, 255, 1)', 
                          fontSize: 26, 
                          fontWeight: '600', 
                          textAlign: 'left',
                          textShadowColor: 'rgba(0, 158, 129, 1)',
                          textShadowOffset: {width: 10, height: 10},
                          textShadowRadius: 10,  
                      }}
                    >
                      Success 
                    </p>
                  </div>
                  :
                  null
              }
            </React.Fragment> */}


            <div className="headerRowBox"> 
                <div 
                    onClick={() => {
                      that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/home',
                        }
                      }) 
                    }}
                >
                    <div className="logoBox"> 
                        <img className="logoImage" src="https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png"></img>
                        <p className="logoText"><b>THE BLENDER PLATFORM</b></p>
                    </div>
                </div>
                <div className="headerRowBoxNavBar">
                  
                </div>
                <div 
                  className="headerUsernameBox"
                > 
                    <p style={{ textAlign: 'right', marginTop: 12.5, }} className="logoText"><b>
                        {/* <span style={{ textAlign: 'right', color: 'rgba(28, 28, 54, 1)' }}>
                            {'User Admin\n'}
                        </span> */}
                        {String(currentUser).toUpperCase()}
                    </b></p>
                    <Button
                        style={{
                        // height: 100,
                        padding: 10,
                        backgroundColor: 'rgba(204, 12, 12, 0)',
                        borderRadius: 3,
                        borderColor: 'rgba(255, 255, 255, 1)',
                        borderStyle: 'solid',
                        borderWidth: 0.5,
                        marginLeft: 25,
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 11, 
                        fontWeight: '600',
                        color: 'rgba(255, 255, 255, 1)',
                        }} 
                        onMouseDown={that.toggleTouched} 
                        onMouseUp={that.handleMouseUp} 
                        onClick={() => { 
                            Auth.signOut()
                            that.setState({
                              navigationDestinationObject: {
                                  navigationRoute: '',
                              },
                            }) 
                        }}
                    >
                        Sign Out
                    </Button>
                </div>
               
            </div>
            <div 
                style={{
                    backgroundColor:'#282c34',
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    /* align-items: center;
                    justify-content: center; */
                }}
            >
                <p 
                    style={{ 
                        position: 'absolute', 
                        top: 135, 
                        left: 25, 
                        color: 'rgba(0, 158, 129, 1)', 
                        fontSize: 18, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                        textUnderlineOffset: 10, 
                        textDecorationLine: 'underline', 
                        textDecorationColor: 'rgba(0, 158, 129, 1)'
                    }}
                >
                    Administrator Center
                    <span 
                        style={{ 
                            textAlign: 'left', 
                            fontSize: 16, 
                            fontWeight: '500', 
                            color: 'rgba(255, 255, 255, 1)', 
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'Verified Public Registry'}
                    </span>
                </p>

                <Button
                  style={{
                  // height: 100,
                  padding: 10,
                  backgroundColor: 'rgba(204, 12, 12, 0)',
                  borderRadius: 3,
                  borderColor: 'rgba(255, 255, 255, 1)',
                  borderStyle: 'solid',
                  borderWidth: 0.5,
                  marginLeft: 25,
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 11, 
                  fontWeight: '600',
                  color: 'rgba(255, 255, 255, 1)',

                  position: 'absolute', 
                  top: 205, 
                  left: 0, 
                  }} 
                  onMouseDown={that.toggleTouched} 
                  onMouseUp={that.handleMouseUp} 
                  onClick={() => {
                      that.setState({
                          navigationDestinationObject: {
                              navigationRoute: '/home',
                          }
                      }) 
                  }}
                >
                    {'<<'} &nbsp; {'Administrator Center Home'}
                </Button>

                <div
                  style={{
                    listStyleType: 'none',
                    paddingBottom: 10,
                    paddingLeft: 10, 
                    paddingRight: 5,
                    position: 'absolute', 
                    top: 265, 
                    left: 25, 
                    width: '90vw',
                    // height: 500,
                    borderWidth: 0.5,
                    borderStyle: 'solid',
                    borderRadius: 3,
                    backgroundColor: 'rgba(0, 158, 129, 0.1)',
                    borderColor: 'rgba(0, 158, 129, 1)',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    display: 'flex'
                  }}
                >





                  <div
                    style={{
                      // width: '45vw',
                      // height: 150,
                      borderRadius: 3,
                      borderWidth: 0.5,
                      paddingRight: '25vw',
                      // borderStyle: 'solid',
                      // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                      borderColor: 'rgba(0, 158, 129, 1)',
                      justifyContent: 'space-between',
                      alignItems: 'self-start',
                      marginTop: 20,
                      left: 0,
                      flexDirection: 'column',
                      display: 'flex',
                      paddingRight: 25,
                    }}
                  >
                    <div
                      style={{
                          // width: '45vw',
                          // height: 150,
                          borderRadius: 3,
                          borderWidth: 0.5,
                          paddingRight: 15,
                          // borderStyle: 'solid',
                          // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                          borderColor: 'rgba(0, 158, 129, 1)',
                          justifyContent: 'space-between',
                          alignItems: 'self-start',
                          // flexDirection: 'row',
                          // display: 'flex',
                          paddingRight: 25,
                      }}
                    >
                      <p 
                        style={{ 
                            // position: 'absolute', 
                            top: 5, 
                            marginLeft: 7, 
                            marginTop: 0,
                            color: 'rgba(255, 255, 255, 1)', 
                            fontSize: 22, 
                            fontWeight: '600', 
                            textAlign: 'left',
                            textShadowColor: 'rgba(255, 255, 255, 1)',
                            textShadowOffset: {width: 10, height: 10},
                            textShadowRadius: 10,  
                        }}
                      >
                        User Lookup
                      </p>
                      <p 
                        style={{ 
                            // position: 'absolute', 
                            top: 5, 
                            marginLeft: 7, 
                            color: 'rgba(255, 255, 255, 1)', 
                            fontSize: 15, 
                            fontWeight: '600', 
                            textAlign: 'left',
                            textShadowColor: 'rgba(255, 255, 255, 1)',
                            textShadowOffset: {width: 10, height: 10},
                            textShadowRadius: 10,  
                            textUnderlineOffset: 2, 
                            textDecorationLine: 'underline', 
                            textDecorationColor: 'rgba(255, 255, 255, 1)',
                        }}
                      >
                        {/* {that.state.overheadApplicationApprovalString}  */}
                      </p>
                    </div>

                    <React.Fragment>
                      <ul
                          style={{
                              listStyleType: 'none',
                              padding: 0,
                              // position: 'absolute', 
                              top: 265, 
                              left: 25, 
                              // width: '45vw',
                              // height: 500,
                              // borderStyle: 'solid',
                              // borderRadius: 3,
                              // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                              // borderColor: 'rgba(0, 158, 129, 1)',
                              justifyContent: 'center',
                              alignItems: 'center',
                          }}
                      >
                        {/* <p 
                          style={{ 
                            textAlign: 'left',
                            fontSize: 15, 
                            fontWeight: '500', 
                            color: 'rgba(0, 158, 129, 1)', 
                            marginTop: 5,
                            marginLeft: 7,
                            marginRight: 10,
                            textUnderlineOffset: 2, 
                            textDecorationLine: 'underline', 
                            textDecorationColor: 'rgba(0, 158, 129, 1)'
                          }}
                        >
                          <b>
                            Member Message
                          </b>
                        </p> */}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <input 
                            type="text" 
                            id="text" 
                            // className="loginInput"  
                            value={that.state.searchedUsername}
                            onChange={(event) => {
                                that.setState({
                                  searchedUsername: event.target.value
                                })
                            }} 
                            name="text"
                            placeholder={`Enter a Username`}
                            style={{
                                fontWeight: '600',
                                fontFamily: 'arial',
                                color: 'rgba(0, 0, 0, 1)',
                                padding: 5,
                                textAlign: 'center',
                                textAlignVertical: 'center',
                                fontSize: 18,
                                marginTop: 5,
                                height: 50,
                                borderRadius: 3,
                                borderWidth: 0.1,
                                borderColor: 'rgba(255, 255, 255, 1)',
                                borderStyle: 'solid',
                            }}
                          />
                          <Button 
                            style={{
                              backgroundColor: 'rgba(0, 158, 129, 1)',
                              fontSize: 15,
                              fontWeight: '600',
                              // color: 'rgba(255, 255, 255, 1)',
                              padding: 5,
                              width: '15vw',
                              height: '5vh',
                              // borderRadius: 1,
                              // // borderWidth: 0.1,
                              // borderColor: 'rgba(255, 255, 255, 1)',
                              // borderStyle: 'solid',
                              marginTop: 15,
                            }} 
                            onMouseDown={that.toggleTouched} 
                            onMouseUp={that.handleMouseUp} 
                            onClick={() => { 
                              that.queryUsername()
                            }}
                          > 
                            Search
                          </Button>
                          <p 
                            style={{ 
                              textAlign: 'left',
                              fontSize: 18, 
                              fontWeight: '500', 
                              color: 'rgba(0, 158, 129, 1)', 
                              marginTop: 50,
                              marginBottom: 15,
                              marginLeft: 7,
                              marginRight: 10,
                            }}
                          >
                            <b>
                              Search Results
                            </b>
                          </p>
                        </div>
                        <div
                          style={{
                            borderRadius: 3,
                            // borderWidth: 0.1,
                            // borderStyle: 'solid',
                            // borderColor: 'rgba(0, 158, 129, 1)',
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            // marginTop: 20,
                            // padding: 3,
                          }}
                        >
             
                              <div
                                style={{
                                  borderRadius: 3,
                                  // borderWidth: 0.1,
                                  // borderStyle: 'solid',
                                  // borderColor: 'rgba(0, 158, 129, 1)',
                                  backgroundColor: 'rgba(255, 255, 255, 1)',
                                  marginTop: 0,
                                  flexDirection: 'column',
                                  flex: 1,
                                  height: '15vh',
                                }}
                              >
                                <p 
                                  style={{ 
                                    textAlign: 'left',
                                    fontSize:  that.state.searchUser_foundBool ? 18 : 16, 
                                    fontWeight: '600', 
                                    color: String(that.state.searchedUsername.length) > 1 ? ( that.state.searchUser_foundBool ? 'rgba(0, 158, 129, 1)' : 'rgba(204, 12, 12, 1)' ) : 'rgba(0, 0, 0, 1)',
                                    marginTop: 5,
                                    marginLeft: 7,
                                    marginRight: 10,
                                  }}
                                >
                                  {
                                    String(that.state.searchedUsername.length) > 1
                                    ?
                                    that.state.searchUser_foundBool ? 'User Found' : 'User Not Found'
                                    :
                                    '...'
                                  }
                                </p>
                                
                                <p 
                                  style={{ 
                                    textAlign: 'left',
                                    fontSize: 15, 
                                    fontWeight: '600', 
                                    color: 'rgba(0, 0, 0, 1)', 
                                    marginTop: 0,
                                    marginLeft: 20,
                                    marginRight: 7,
                                  }}
                                >
                                  
                                  {String(that.state.searchedUser_foundUsername).toUpperCase()} 
                                  <br/>
                                  {String(that.state.searchedUser_fullName)} 
                                </p>
                                {/* <p 
                                  style={{ 
                                    textAlign: 'right',
                                    fontSize: 10, 
                                    fontWeight: '600', 
                                    color: listitem.senderUsername.toLowerCase() == applicantUsername ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 1)', 
                                    marginTop: 5,
                                    marginLeft: 7,
                                    marginRight: 10,
                                  }}
                                >
                                  - {String(listitem.senderUsername).toUpperCase()}
                                </p>
                                <p 
                                  style={{ 
                                    textAlign: 'right',
                                    fontSize: 10, 
                                    fontWeight: '600', 
                                    color: 'rgba(0, 0, 0, 1)', 
                                    marginTop: 0,
                                    marginLeft: 7,
                                    marginRight: 10,
                                  }}
                                >
                                  {that.formatNewDateObject(listitem.timestamp)}
                                </p> */}
                              </div>
                        </div>
                      </ul>
                    </React.Fragment>
                  </div>





                  <div
                    style={{
                      // width: '45vw',
                      // height: 150,
                      borderRadius: 3,
                      borderWidth: 0.5,
                      paddingRight: 15,
                      // borderStyle: 'solid',
                      // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                      position: 'absolute',
                      borderColor: 'rgba(0, 158, 129, 1)',
                      justifyContent: 'space-between',
                      alignItems: 'self-start',
                      flexDirection: 'column',
                      display: 'flex',
                      paddingRight: 25,
                      right: 0,
                      top: 25,
                    }}
                  > 
                    <div
                      style={{
                          // width: '45vw',
                          // height: 150,
                          borderRadius: 3,
                          borderWidth: 0.5,
                          // borderStyle: 'solid',
                          paddingTop: 20,
                          // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                          borderColor: 'rgba(0, 158, 129, 1)',
                          justifyContent: 'space-between',
                          alignItems: 'self-start',
                          flexDirection: 'column',
                          display: 'flex',
                      }}
                    >
                
                      {
                        that.state.searchedUser_foundUsername 
                        ? 
                        <div
                          style={{
                            marginBottom: 25,
                            height: 50,
                            // padding: 8,
                            // marginLeft: 10,
                            marginTop: 0,
                            backgroundColor: that.state.searchedUser_foundUsername ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.1)',
                            borderRadius: 5,
                            // borderColor:  that.state.searchedUser_foundUsername ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.1)',
                            // borderStyle: 'solid',
                            // borderWidth: 0.5,
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 14, 
                            fontWeight: '600',
                            // flex: 1,
                            // flexDirection: 'row',
                            // color: that.state.searchedUser_foundUsername ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.1)',
                          }}
                          // disabled={true}
                        >
                          <input 
                            type="file" 
                            // multiple="multiple"
                            onChange={that.handleChange}
                            style={{
                              // marginBottom: 25,
                              height: 50,
                              // padding: 8,
                              marginLeft: 20,
                              marginRight: 20,
                              marginTop: 15,
                              marginBottom: 15,
                              // backgroundColor: that.state.searchedUser_foundUsername ? 'rgba(0, 158, 129, 1)' : 'rgba(0, 158, 129, 0.1)',
                              // borderRadius: 1,
                              // borderColor:  that.state.searchedUser_foundUsername ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.1)',
                              // borderStyle: 'solid',
                              // borderWidth: 0.5,
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: 14, 
                              fontWeight: '600',
                              // flex: 1,
                              // flexDirection: 'row',
                              // color: that.state.searchedUser_foundUsername ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.1)',
                            }}
                          
                          />
                        </div>
                        : 
                        null
                      }

                      <Button
                        onMouseDown={that.toggleTouched} 
                        onMouseUp={that.handleMouseUp} 
                        disabled={!that.state.searchedUser_foundUsername || that.state.file == null}
                        onClick={that.handleSubmit}
                      >
                        Upload Verified Registry Files: {String(that.state.searchedUser_foundUsername).toUpperCase()}
                      </Button>

                      {that.state.uploadedFileURL && <img src={that.state.uploadedFileURL} alt="Uploaded content"/>}
                      {this.state.didFileUpload && <p className='success-message'>File uploaded successfully {this.state.uploadedFileURL}</p> }

                      {
                        that.state.searchedUser_foundUsername 
                        ? 
                        <Button
                          style={{
                          //   // height: 100,
                          //   padding: 8,
                          //   marginLeft: 10,
                            marginTop: 100,
                            backgroundColor: that.state.searchedUser_foundUsername ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.1)',
                          //   borderRadius: 1,
                          //   borderColor:  that.state.searchedUser_foundUsername ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.1)', 
                          //   borderStyle: 'solid',
                          //   borderWidth: 0.5,
                          //   justifyContent: 'center',
                          //   alignItems: 'center',
                          //   fontSize: 14, 
                          //   fontWeight: '600',
                          //   color: that.state.searchedUser_foundUsername ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.1)',
                          }} 
                          href={`/vr_appshow/${this.state.searchedUser_foundUsername}`}
                          onMouseDown={that.toggleTouched} 
                          onMouseUp={that.handleMouseUp} 
                        >
                          Open VR Application of: {String(that.state.searchedUser_foundUsername).toUpperCase()}
                        </Button>
                        : 
                        null
                      }
                    </div>
                  </div>
                  



                </div>
            </div>
        </div>
        );
    }

}

export default VR_ApplicationShow;
