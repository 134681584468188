import Amplify, { Auth, Storage, API } from 'aws-amplify'
import './IC_PendingFileDocumentViewer.css'; // Tell webpack that Button.js uses these styles
import React, {Component, Fragment, useRef} from 'react';
import awsconfig from '../aws-exports.js';
import { navigationHandler, routeProps } from '../utilities/navigator.js';
import LoadingIcons from 'react-loading-icons'
import {Buffer} from 'buffer';
import { tokenLifeTimeLimit, checkValidAuthTokenIntervalPeriod_ms,  LENDERBLOCKADMINWHITELIST} from '../blenderData/securityConfigurations';

import 'semantic-ui-css/semantic.min.css'
import { Button, Modal, Header, Image, } from 'semantic-ui-react'

var AWS = require('aws-sdk');

var checkValidAuthTokenInterval;

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

var cognitoID;
var currentUser;
var currentUserCap;
var stripeAccountID;
var stripeCustomerID;
var primaryDisplayCurrency;
var secondaryDisplayCurrency;
var publicKey;
var corporateBackedUsernamesArray;

let that;

let applicantUsername;

let monthLengtheningEnum = {
    JAN: 'January',
    FEB: 'February',
    MAR: 'March',
    APR: 'April',
    MAY: 'May',
    JUN: 'June',
    JUL: 'July',
    AUG: 'August',
    SEP: 'September',
    OCT: 'October',
    NOV: 'November',
    DEC: 'December',
}

class IC_PendingFileDocumentViewer extends Component {

    constructor() {
        super()

        this.state = {
            usernameText: '',
            passwordText: '',
            touched: false,
            navigationDestinationObject: {
                navigationRoute: '',
            },
            button_recentApp_opacity: 1,
            button_pendingApp_opacity: 1,
            pendingApplicationsList: [],
            requestedAwaitingDocumentList: [],
            messageLineArray: [],
            submittedDocumentIDList: [],
            selectedDocumentData: [],
            documentReviewerNotesString: '',
            thisToBeAcceptedDocumentName: null,
            instancesSubmitted: null,
            showLoaderOverlay: false,
        };
        that = this

    }

    checkValidAuthToken(){
        let navigationDestinationObject = {
          navigationRoute: ''
        };
        
        Auth.currentAuthenticatedUser().then((res) => {
            if(res){
    
                currentUser = res.username
        
                if(!LENDERBLOCKADMINWHITELIST.includes(String(currentUser).toLowerCase())){
                    Auth.signOut()
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    }) 
                }
            } else {
                Auth.signOut()
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                }) 
            }
        }).catch((err) => {
            Auth.signOut()
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login',
                },
            }) 
        })
        
        
        checkValidAuthTokenInterval = setInterval(() => {
          Auth.currentAuthenticatedUser().then((res) => {
            if(res){
              let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
              let tokenLifetime = Date.now() - authTokenIssueTime
      
              if(tokenLifetime >= tokenLifeTimeLimit){
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                  return navigationHandler(navigationDestinationObject)
                }
              }
            } else {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                    return navigationHandler(navigationDestinationObject)
                }
            }
          }).catch(() => {
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login'
                }
            })
            if(navigationHandler(navigationDestinationObject)){
              return navigationHandler(navigationDestinationObject)
            }
          })
        }, checkValidAuthTokenIntervalPeriod_ms)
    }

    componentWillUnmount(){

    }
    componentDidMount(){
        that.checkValidAuthToken()

        that.initialMountComponents()

        applicantUsername = localStorage.getItem('applicantUsername')

            this.setState({
                usernameText: '',
                passwordText: '',
                touched: false,
                navigationDestinationObject: {
                    navigationRoute: '',
                },
                button_recentApp_opacity: 1,
                button_pendingApp_opacity: 1,
                pendingApplicationsList: [],
                requestedAwaitingDocumentList: [],
                messageLineArray: [],
                submittedDocumentIDList: [],
                selectedDocumentData: [],
                documentReviewerNotesString: '',
                thisToBeAcceptedDocumentName: null,
                instancesSubmitted: null,
                showLoaderOverlay: true,
            })
            
            Auth.currentAuthenticatedUser().then((res) => {
                if(res){
                    let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
                    let tokenLifetime = Date.now() - authTokenIssueTime
                    if(tokenLifetime < tokenLifeTimeLimit){
                        currentUser = res.username
                        cognitoID = res.signInUserSession.idToken.jwtToken
                                        
                        currentUserCap = currentUser.toUpperCase()
                        if(currentUserCap.length > 10){
                            currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
                        }
                        
                        that.setState({
                            currentUser,
                            navigationDestinationObject: {
                                navigationRoute: '',
                            },
                        })
                    } else {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/login',
                            },
                        })
                    }
                } else {
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    })
                }
            
            }).catch((err) => {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                })
            })
    }

    toggleTouched = () => {
        this.setState( prevState => ({
        touched: !prevState.touched
        }));
    }
    
    handleMouseUp = () => {
        // Handle smooth animation when clicking without holding
        setTimeout( () => {
        this.setState({ touched: false });
        }, 150);
    }



    initialMountComponents(){

        Auth.currentAuthenticatedUser().then((res) => {
            currentUser = res.username
            cognitoID = res.signInUserSession.idToken.jwtToken

            currentUserCap = currentUser.toUpperCase()
            if(currentUserCap.length > 10){
                currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
            }
            that.setState({ 
                currentUser,
                selectedDocumentData: [],
            })
            
            API.put('api1169ba1e', '', { 
                headers: { Authorization: `Bearer ${cognitoID}` },
                body: { 
                category: 'db',
                tableName: 'users', 
                primaryKey: String(applicantUsername), 
                requestOperation: 'get',
                }
            }).then((userData) => {
                
            
                API.put('api1169ba1e', '', { 
                  headers: { Authorization: `Bearer ${cognitoID}` },
                  body: { 
                      category: 'db',
                      tableName: 'growthDeveloperContractors', 
                      primaryKey: applicantUsername, 
                      requestOperation: 'get',
                  }
                }).then((growthDeveloperContractors) => {
                  let requestedAwaitingDocumentKeyArray = growthDeveloperContractors.Item.requestedAwaitingDocumentKeyArray
                  let requestedAwaitingDocumentKeyObjects = growthDeveloperContractors.Item.requestedAwaitingDocumentKeyObjects
                  let messageLineArray = growthDeveloperContractors.Item.messageLineArray

                  if(String(messageLineArray[0].messageText).length == 0){
                    messageLineArray.splice(0,1)
                  }
                  messageLineArray = messageLineArray.reverse();

                  let allDocumentFileIDs = growthDeveloperContractors.Item.allDocumentFileIDs
                  let adminReviewUpdates = growthDeveloperContractors.Item.adminReviewUpdates
                  let lastReviewedDocumentID = adminReviewUpdates.lastViewedDocumentID

                  let submittedDocumentIDList = []

                  function loopSubmittedDocumentIDs(d){
                  // for(let d=allDocumentFileIDs.length-1; d>=0; d--){
                    if(d >= 0){
                      let thisDocumentFileID = allDocumentFileIDs[d]
                      let _d = d

                      API.put('api1169ba1e', '', { 
                        headers: { Authorization: `Bearer ${cognitoID}` },
                        body: { 
                            category: 'db',
                            tableName: 'independentContractorDocumentFileIDMaps', 
                            primaryKey: thisDocumentFileID, 
                            requestOperation: 'get',
                        }
                      }).then((independentContractorDocumentFileIDMaps) => {

                        AWS.config.update({region:'us-east-1'});
                            
                        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                            IdentityPoolId: 'us-east-1:8973ceec-5da3-423f-b3c6-e31ebdc08ce8',
                            Logins: { 
                                'cognito-idp.us-east-1.amazonaws.com/us-east-1_vS08WURWh': cognitoID,
                            }
                        })

                        AWS.config.credentials.refresh((error) => {
                            if (error) {
                                return error
                            }
                        });
                        
                        const s3 = new AWS.S3();

                        var getParams = {
                            Bucket: 'private-independent-contractor-document-submissions',                           
                            Key: `${applicantUsername}/${thisDocumentFileID}`  
                        }

                        s3.getObject(getParams, async function(err, data) {
                          let base64Bytes = Buffer.from(data.Body).toString('base64');

                          let dataType = data.ContentType

                          let fileApprovalStatus = independentContractorDocumentFileIDMaps.Item.fileApprovalStatus
                          let username = independentContractorDocumentFileIDMaps.Item.username

                          let documentSubmissionTimestamp = new Date(Number(thisDocumentFileID.substring(0,13)))
                          documentSubmissionTimestamp = that.formatNewDateObject(documentSubmissionTimestamp)

                          if(Number(String(thisDocumentFileID).substring(0,13)) > Number(String(lastReviewedDocumentID).substring(0,13)) && fileApprovalStatus.toLowerCase() == 'pending'){
                            submittedDocumentIDList.push({
                              fileApprovalStatus,
                              username,
                              documentSubmissionTimestamp,
                              dataType,
                              documentFileID: thisDocumentFileID,
                              key: String(thisDocumentFileID).substring(0,13),
                              base64BytesImageSRC: String(`data:${dataType};base64,${String(base64Bytes)}`)
                            })
                            if(_d == 0){
                              getRequestedDocuments()
                            } else {
                              loopSubmittedDocumentIDs(d-1)
                            }
                          } else {
                            if(_d == 0){
                              getRequestedDocuments()
                            } else {
                              loopSubmittedDocumentIDs(d-1)
                            }
                          }
                        })
                      })
                    }
                  }
                  loopSubmittedDocumentIDs(allDocumentFileIDs.length-1)

                  function getRequestedDocuments(){
                    let requestedAwaitingDocumentList = []
                    for(let r=0; r<=requestedAwaitingDocumentKeyArray.length-1; r++){
                      requestedAwaitingDocumentList.push({
                        documentName: requestedAwaitingDocumentKeyArray[r],
                        documentDescription: requestedAwaitingDocumentKeyObjects[requestedAwaitingDocumentKeyArray[r]].publicInfoSummary,
                        key: String(Math.random())
                      })

                      if(r == requestedAwaitingDocumentKeyArray.length-1){
                        requestedAwaitingDocumentList.push({
                            documentName: 'Invalid File, N/A',
                            documentDescription: 'This file either is corrupted or does not match any requested documents.',
                            key: String(Math.random())
                          })
                        that.setState({
                          requestedAwaitingDocumentList,
                          messageLineArray,
                          submittedDocumentIDList,
                          thisToBeAcceptedDocumentName: null,
                          documentReviewerNotesString: '',
                          showLoaderOverlay: false,
                        })

                      }
                    }
                  }
                })    
            })
        });
    }

    acceptDocument(){
       
        if(
            that.state.documentReviewerNotesString &&
            that.state.thisToBeAcceptedDocumentName &&
            !String(that.state.thisToBeAcceptedDocumentName).toLowerCase().includes('invalid')
        ){
            window.scrollTo(0, 0)
            that.setState({
                showLoaderOverlay: true
            })

            API.put('api1169ba1e', '', { 
                headers: { Authorization: `Bearer ${cognitoID}` },
                body: { 
                    category: 'db',
                    tableName: 'growthDeveloperContractors', 
                    primaryKey: applicantUsername, 
                    requestOperation: 'get',
                }
              }).then((growthDeveloperContractors) => {
                let requestedAwaitingDocumentKeyObjects = growthDeveloperContractors.Item.requestedAwaitingDocumentKeyObjects
                let instancesSubmitted = requestedAwaitingDocumentKeyObjects[that.state.thisToBeAcceptedDocumentName].instancesSubmitted


                API.put('api1169ba1e', '', { 
                    headers: { Authorization: `Bearer ${cognitoID}` },
                    body: { 
                        category: 'db',
                        tableName: 'growthDeveloperContractors', 
                        primaryKey: applicantUsername, 
                        requestOperation: 'update',
                        updateExpression: `SET 
                            requestedAwaitingDocumentKeyObjects.#docName.privateReviewerNotes = :privateReviewerNotes,
                            requestedAwaitingDocumentKeyObjects.#docName.instancesSubmitted = :instancesSubmitted,
                            requestedAwaitingDocumentKeyObjects.#docName.documentSatisfiedBool = :documentSatisfiedBool,
                            requestedAwaitingDocumentKeyObjects.#docName.documentFileIDs = list_append(requestedAwaitingDocumentKeyObjects.#docName.documentFileIDs, :documentFileIDs)
                        `, 
                        expressionAttributeNames: {
                            '#docName': that.state.thisToBeAcceptedDocumentName,
                            // '#instancesSubmitted': 'instancesSubmitted',
                            // '#documentSatisfiedBool': 'documentSatisfiedBool',
                            // '#documentFileIDs': 'documentFileIDs',
                        },
                        expressionAttributeValues: { 
                            ':privateReviewerNotes': that.state.documentReviewerNotesString,
                            ':instancesSubmitted': Number(instancesSubmitted) + 1,
                            ':documentSatisfiedBool': true,
                            ':documentFileIDs': [that.state.selectedDocumentData[0].documentFileID]
                        }
                    }
                }).then((res) => {
                    API.put('api1169ba1e', '', { 
                        headers: { Authorization: `Bearer ${cognitoID}` },
                        body: { 
                            category: 'db',
                            tableName: 'independentContractorDocumentFileIDMaps', 
                            primaryKey: that.state.selectedDocumentData[0].documentFileID, 
                            requestOperation: 'update',
                            updateExpression: `SET 
                                fileApprovalStatus = :fileApprovalStatus
                            `, 
                            expressionAttributeValues: { 
                                ':fileApprovalStatus': 'approved',
                            }
                        }
                    }).then((res) => {
                        that.initialMountComponents()

                    })
                })
            })

        } else {

            that.setState({
                showLoaderOverlay: false
            })
        }
      
    }

    rejectDocument(){
        

        if(
            that.state.documentReviewerNotesString &&
            that.state.thisToBeAcceptedDocumentName &&
            String(that.state.thisToBeAcceptedDocumentName).toLowerCase().includes('invalid')
        ){
            window.scrollTo(0, 0)
            that.setState({
                showLoaderOverlay: true
            })

            API.put('api1169ba1e', '', { 
                headers: { Authorization: `Bearer ${cognitoID}` },
                body: { 
                    category: 'db',
                    tableName: 'independentContractorDocumentFileIDMaps', 
                    primaryKey: that.state.selectedDocumentData[0].documentFileID, 
                    requestOperation: 'update',
                    updateExpression: `SET 
                        fileApprovalStatus = :fileApprovalStatus
                    `, 
                    expressionAttributeValues: { 
                        ':fileApprovalStatus': 'denied',
                    }
                }
            }).then((res) => {
                that.initialMountComponents()

            })

        } else {

            that.setState({
                showLoaderOverlay: false
            })
        }

    }

    formatNewDateObject(documentSubmissionTimestamp){

      documentSubmissionTimestamp = String(documentSubmissionTimestamp)

      documentSubmissionTimestamp = monthLengtheningEnum[String(documentSubmissionTimestamp.substring(4,7)).toUpperCase()] + String(documentSubmissionTimestamp.substring(7,10)) + ',' + String(documentSubmissionTimestamp.substring(10,15))
      return documentSubmissionTimestamp;
    }

    render(){

        const { touched } = this.state;
        const className = touched ? 'button1_touched' : 'button1';

        applicantUsername = localStorage.getItem('applicantUsername')

        if(navigationHandler(that.state.navigationDestinationObject)){
            return navigationHandler(that.state.navigationDestinationObject)
        }


        return (
        <div className="IC_PendingFileDocumentViewer">
            <React.Fragment>
                {
                    that.state.showLoaderOverlay
                    ?
                    <div
                        style={{
                            position: 'absolute',
                            // top: '2vh',
                            // right: '2vw',
                            // left: '2vw',
                            width: '100vw',
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                            padding: 10,
                            zIndex: 2000,
                        }}
                    >
                        <LoadingIcons.BallTriangle 
                            style={{
                                marginTop: '45vh',
                                marginBottom: '45vh',
                                marginRight: '42vw',
                                marginLeft: '42vw',
                            }} 
                            strokeWidth={1}
                            stroke="rgba(255, 255, 255, 1)" 
                            fill="rgba(0, 158, 129, 1)" 
                             
                        />
                    </div>
                    :
                    null
                }
            </React.Fragment>
            <div className="headerRowBox"> 
                <div 
                    onClick={() => {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/home',
                            }
                        }) 
                    }}
                >
                    <div className="logoBox"> 
                        <img className="logoImage" src="https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png"></img>
                        <p className="logoText"><b>THE BLENDER PLATFORM</b></p>
                    </div>
                </div>
                <div className="headerRowBoxNavBar">
                    
                  
                </div>
                <div className="headerUsernameBox"> 
                    <p style={{ textAlign: 'right', marginTop: 12.5, }} className="logoText"><b>
                        {/* <span style={{ textAlign: 'right', color: 'rgba(28, 28, 54, 1)' }}>
                            {'User Admin\n'}
                        </span> */}
                        {String(currentUser).toUpperCase()}
                    </b></p>
                    <Button
                        style={{
                        // height: 100,
                        padding: 10,
                        backgroundColor: 'rgba(204, 12, 12, 0)',
                        borderRadius: 3,
                        borderColor: 'rgba(255, 255, 255, 1)',
                        borderStyle: 'solid',
                        borderWidth: 0.5,
                        marginLeft: 25,
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 11, 
                        fontWeight: '600',
                        color: 'rgba(255, 255, 255, 1)',
                        }} 
                        onMouseDown={this.toggleTouched} 
                        onMouseUp={this.handleMouseUp} 
                        onClick={() => { 
                            Auth.signOut()
                            that.setState({
                                navigationDestinationObject: {
                                    navigationRoute: '/login',
                                },
                            }) 
                        }}
                    >
                        Sign Out
                    </Button>
                </div>
            </div>
            <div 
                style={{
                    backgroundColor:'#282c34',
                    width: '100%',
                    minHeight: '3000vh',
                    display: 'flex',
                    flexDirection: 'column',
                    /* align-items: center;
                    justify-content: center; */
                }}
            >
                <p 
                    style={{ 
                        position: 'absolute', 
                        top: 135, 
                        left: 25, 
                        color: 'rgba(0, 158, 129, 1)', 
                        fontSize: 18, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                        textUnderlineOffset: 10, 
                        textDecorationLine: 'underline', 
                        textDecorationColor: 'rgba(0, 158, 129, 1)'
                    }}
                >
                    Administrator Center
                    <span 
                        style={{ 
                            textAlign: 'left', 
                            fontSize: 16, 
                            fontWeight: '500', 
                            color: 'rgba(255, 255, 255, 1)', 
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'Independent Contractor Management'}
                    </span>
                </p>
                
                <Button
                    style={{
                    // height: 100,
                    padding: 10,
                    backgroundColor: 'rgba(204, 12, 12, 0)',
                    borderRadius: 3,
                    borderColor: 'rgba(255, 255, 255, 1)',
                    borderStyle: 'solid',
                    borderWidth: 0.5,
                    marginLeft: 25,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 11, 
                    fontWeight: '600',
                    color: 'rgba(255, 255, 255, 1)',

                    position: 'absolute', 
                    top: 205, 
                    left: 0, 
                    }} 
                    onMouseDown={this.toggleTouched} 
                    onMouseUp={this.handleMouseUp} 
                    onClick={() => {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/ic_appshow',
                            }
                        }) 
                    }}
                >
                    {'<<'} &nbsp; {'Contractor Application Review'}
                </Button>

                <div
                  style={{
                    listStyleType: 'none',
                    paddingBottom: 10,
                    paddingLeft: 10, 
                    paddingRight: 5,
                    position: 'absolute', 
                    top: 265, 
                    left: 25, 
                    width: '90vw',
                    // height: 500,
                    borderWidth: 0.5,
                    borderStyle: 'solid',
                    borderRadius: 3,
                    backgroundColor: 'rgba(0, 158, 129, 0.1)',
                    borderColor: 'rgba(0, 158, 129, 1)',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                <p 
                    style={{ 
                        // position: 'absolute', 
                        top: 5, 
                        marginLeft: 7, 
                        color: 'rgba(255, 255, 255, 1)', 
                        fontSize: 18, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                    }}
                >
                    Document Acceptance Review
                </p>
                <p 
                    style={{ 
                        // position: 'absolute', 
                        top: 5, 
                        marginLeft: 7, 
                        color: 'rgba(255, 255, 255, 1)', 
                        fontSize: 15, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                    }}
                >
                    {'Contractor Username: '} 
                    <span 
                        style={{ 
                            textAlign: 'left', 
                            fontSize: 15, 
                            fontWeight: '500', 
                            color: 'rgba(255, 255, 255, 1)', 
                        }}
                    >
                    <b>
                        {String(applicantUsername).toUpperCase()}
                    </b>
                    </span>
                </p>
               
                <div
                      style={{
                          // width: '45vw',
                          // height: 150,
                          borderRadius: 3,
                          borderWidth: 0.5,
                          paddingRight: 15,
                          // borderStyle: 'solid',
                          // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                          borderColor: 'rgba(0, 158, 129, 1)',
                          justifyContent: 'space-between',
                          alignItems: 'self-start',
                          marginTop: 20,
                          flexDirection: 'row',
                          display: 'flex',
                      }}
                  >
                    <React.Fragment>
                      <ul
                          style={{
                              listStyleType: 'none',
                              padding: 0,
                              // position: 'absolute', 
                              top: 265, 
                              left: 25, 
                              marginRight: 100,
                              // width: '45vw',
                              // height: 500,
                              // borderStyle: 'solid',
                              // borderRadius: 3,
                              // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                              // borderColor: 'rgba(0, 158, 129, 1)',
                              justifyContent: 'center',
                              alignItems: 'center',
                          }}
                      >
                        <p 
                          style={{ 
                            textAlign: 'left',
                            fontSize: 15, 
                            fontWeight: '500', 
                            color: 'rgba(0, 158, 129, 1)', 
                            marginTop: 5,
                            marginLeft: 7,
                            marginRight: 10,
                            textUnderlineOffset: 2, 
                            textDecorationLine: 'underline', 
                            textDecorationColor: 'rgba(0, 158, 129, 1)'
                          }}
                        >
                          <b>
                            Received Documents Pending Approval
                          </b>
                        </p>
                        {that.state.submittedDocumentIDList.map(listitem => (

                          <li
                            key={listitem.key}
                            style={{
                                // width: '45vw',
                                // height: 150,
                                // borderRadius: 3,
                                textAlign: 'left',
                                paddingBottom: 20,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0.1,
                                borderStyle: 'solid',
                                borderColor: 'rgba(0, 158, 129, 1)',
                                // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                // justifyContent: 'center',
                                // alignItems: 'center',
                                marginBottom: 20,
                            }}
                          >
                            <div 
                                style={{
                                    textAlign: 'left',
                                }}
                                onClick={() => {
                                    that.setState({
                                        selectedDocumentData: [{
                                            base64BytesImageSRC: listitem.base64BytesImageSRC,
                                            documentSubmissionTimestamp: listitem.documentSubmissionTimestamp,
                                            documentFileID: listitem.documentFileID,
                                            dataType: listitem.dataType,
                                        }]
                                    })
                                }}

                            //   to={{
                            //       pathname: '/ic_pendingfileviewer',
                            //       // state: {
                            //       //   applicantUsername: listitem.thisApplicantUsername.toLowerCase()
                            //       // }
                            //   }}
                            >
                                <p 
                                    style={{ 
                                    textAlign: 'left',
                                    fontSize: 11, 
                                    fontWeight: '500', 
                                    color: 'rgba(255, 255, 255, 1)', 
                                    marginTop: 5,
                                    marginBottom: 10,
                                    marginLeft: 7,
                                    marginRight: 10,
                                    }}
                                >
                                    Submitted: {listitem.documentSubmissionTimestamp}
                                </p>
                                <p 
                                    style={{ 
                                    textAlign: 'left',
                                    fontSize: 11, 
                                    fontWeight: '500', 
                                    color: 'rgba(255, 255, 255, 1)', 
                                    marginTop: 5,
                                    marginBottom: 20,
                                    marginLeft: 7,
                                    marginRight: 10,
                                    }}
                                >
                                    File ID: {String(listitem.documentFileID).toUpperCase()}
                                </p>
                                {
                                    listitem.dataType == 'application/pdf'
                                    ?
                                    <embed 
                                        onClick={() => {
                                            that.setState({
                                                selectedDocumentData: [{
                                                    base64BytesImageSRC: listitem.base64BytesImageSRC,
                                                    documentSubmissionTimestamp: listitem.documentSubmissionTimestamp,
                                                    documentFileID: listitem.documentFileID,
                                                    dataType: listitem.dataType,
                                                }]
                                            })
                                        }}
                                        style={{
                                            marginLeft: 7,
                                            textAlign: 'left',
                                            maxHeight: 500,
                                            borderWidth: 0.5,
                                            borderStyle: 'solid',
                                            borderRadius: 1,
                                            borderColor: 'rgba(255, 255, 255, 1)',
                                        }}
                                        src={listitem.base64BytesImageSRC}
                                    />
                                    :
                                    <img 
                                        onClick={() => {
                                            that.setState({
                                                selectedDocumentData: [{
                                                    base64BytesImageSRC: listitem.base64BytesImageSRC,
                                                    documentSubmissionTimestamp: listitem.documentSubmissionTimestamp,
                                                    documentFileID: listitem.documentFileID,
                                                    dataType: listitem.dataType,
                                                }]
                                            })
                                        }}
                                        style={{
                                            marginLeft: 7,
                                            textAlign: 'left',
                                            right: '100%',
                                            maxHeight: 100,
                                            borderWidth: 0.5,
                                            borderStyle: 'solid',
                                            borderRadius: 1,
                                            borderColor: 'rgba(255, 255, 255, 1)',
                                        }}
                                        src={listitem.base64BytesImageSRC}
                                    />
                                }
                             
                            </div>
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                    <React.Fragment>
                      <ul
                          style={{
                              listStyleType: 'none',
                              padding: 0,
                              // position: 'absolute', 
                              top: 265, 
                              left: 25, 
                              // width: '45vw',
                              // height: 500,
                              // borderStyle: 'solid',
                              // borderRadius: 3,
                              // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                              // borderColor: 'rgba(0, 158, 129, 1)',
                              justifyContent: 'center',
                              alignItems: 'center',
                          }}
                      >
                       
                        {that.state.selectedDocumentData.map(listitem => (
                            
                            <li
                                key={listitem.documentFileID}
                                style={{
                                    // width: '45vw',
                                    // height: 150,
                                    // borderRadius: 3,
                                    textAlign: 'left',
                                    // paddingBottom: 20,
                                    // borderTopWidth: 0,
                                    // borderLeftWidth: 0,
                                    // borderRightWidth: 0,
                                    // borderBottomWidth: 0.1,
                                    // borderStyle: 'solid',
                                    // borderColor: 'rgba(0, 158, 129, 1)',
                                    // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    marginBottom: 20,
                                }}
                            >
                                <p 
                                    style={{ 
                                    textAlign: 'left',
                                    fontSize: 11, 
                                    fontWeight: '500', 
                                    color: 'rgba(255, 255, 255, 1)', 
                                    marginTop: 5,
                                    marginBottom: 10,
                                    marginLeft: 7,
                                    marginRight: 10,
                                    }}
                                >
                                    Submitted: {listitem.documentSubmissionTimestamp}
                                </p>
                                <p 
                                    style={{ 
                                    textAlign: 'left',
                                    fontSize: 11, 
                                    fontWeight: '500', 
                                    color: 'rgba(255, 255, 255, 1)', 
                                    marginTop: 5,
                                    marginBottom: 20,
                                    marginLeft: 7,
                                    marginRight: 10,
                                    }}
                                >
                                    File ID: {String(listitem.documentFileID).toUpperCase()}
                                </p>
                                {
                                    listitem.dataType == 'application/pdf'
                                    ?
                                    <embed 
                                        style={{
                                        textAlign: 'left',
                                        width: '50vw',
                                        height: '63vw',
                                        borderWidth: 0.5,
                                        borderStyle: 'solid',
                                        borderRadius: 1,
                                        borderColor: 'rgba(255, 255, 255, 1)',
                                        }}
                                        src={listitem.base64BytesImageSRC}
                                    />
                                    :
                                    <img 
                                        style={{
                                        textAlign: 'left',
                                        right: '100%',
                                        maxWidth: '50vh',
                                        borderWidth: 0.5,
                                        borderStyle: 'solid',
                                        borderRadius: 1,
                                        borderColor: 'rgba(255, 255, 255, 1)',
                                        }}
                                        src={listitem.base64BytesImageSRC}
                                    />
                                }
                                <React.Fragment>
                                    <ul
                                        style={{
                                            listStyleType: 'none',
                                            padding: 0,
                                            // position: 'absolute', 
                                            top: 265, 
                                            left: 25, 
                                            // width: '45vw',
                                            // height: 500,
                                            // borderStyle: 'solid',
                                            // borderRadius: 3,
                                            // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                            // borderColor: 'rgba(0, 158, 129, 1)',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <p 
                                            style={{ 
                                                textAlign: 'left',
                                                fontSize: 15, 
                                                fontWeight: '500', 
                                                color: 'rgba(0, 158, 129, 1)', 
                                                marginTop: 25,
                                                marginLeft: 7,
                                                marginRight: 10,
                                                textUnderlineOffset: 2, 
                                                textDecorationLine: 'underline', 
                                                textDecorationColor: 'rgba(0, 158, 129, 1)'
                                            }}
                                        >
                                            <b>
                                                File Submission Review Process
                                            </b>
                                        </p>
                                        <p 
                                            style={{ 
                                                textAlign: 'left',
                                                fontSize: 12, 
                                                fontWeight: '500', 
                                                color: 'rgba(255, 255, 255, 1)', 
                                                marginTop: 25,
                                                marginLeft: 7,
                                                marginRight: 10,
                                            }}
                                        >
                                            <b>
                                                Step 1: Which Due Document Does this Satisfy?
                                            </b>
                                        </p>
                                        {that.state.requestedAwaitingDocumentList.map(listitem => (

                                            <li
                                                key={listitem.key}
                                                style={{
                                                    width: '35vw',
                                                    // height: 150,
                                                    borderRadius: 3,
                                                    borderWidth: 0.5,
                                                    borderStyle: 'solid',
                                                    backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                                    borderColor: 'rgba(0, 158, 129, 0)',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 20,
                                                }}
                                            >
                                                <Button 
                                                     style={{
                                                        borderRadius: 3,
                                                        borderWidth: 0.5,
                                                        borderStyle: 'solid',
                                                        borderColor: 'rgba(0, 158, 129, 0)',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        paddingLeft: 7,
                                                        paddingRight: 7,
                                                        backgroundColor: that.state.thisToBeAcceptedDocumentName == listitem.documentName ? 'rgba(0, 158, 129, 1)' : 'rgba(0, 158, 129, 0.25)',
                                                    }}
                                                    onClick={() => {
                                                        that.setState({
                                                            thisToBeAcceptedDocumentName: listitem.documentName,
                                                        })
                                                    }}
                                                    
                                                >
                                                    <p 
                                                        style={{ 
                                                            textAlign: 'left',
                                                            fontSize: 12, 
                                                            fontWeight: '600', 
                                                            color: that.state.thisToBeAcceptedDocumentName == listitem.documentName ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.75)', 
                                                        }}
                                                    >
                                                        {listitem.documentName}
                                                    </p>
                                                </Button>
                                            </li>
                                        ))}
                                    </ul>
                                </React.Fragment>
                                <p 
                                    style={{ 
                                        textAlign: 'left',
                                        fontSize: 12, 
                                        fontWeight: '500', 
                                        color: 'rgba(255, 255, 255, 1)', 
                                        marginTop: 25,
                                        marginLeft: 7,
                                        marginRight: 10,
                                    }}
                                >
                                    <b>
                                        Step 2: Add Document Reviewer Notes
                                    </b>
                                </p>
                                <textarea 
                                    type="text" 
                                    id="text" 
                                    // className="loginInput"  
                                    value={that.state.documentReviewerNotesString}
                                    onChange={(event) => {
                                        that.setState({
                                            documentReviewerNotesString: event.target.value
                                        })
                                    }} 
                                    name="text"
                                    placeholder='Leave Reviewer Note '
                                    style={{
                                        fontWeight: '600',
                                        fontFamily: 'arial',
                                        color: 'rgba(0, 0, 0, 1)',
                                        padding: 5,
                                        textAlign: 'left',
                                        textAlignVertical: 'center',
                                        fontSize: 15,
                                        marginTop: 5,
                                        width: '60vw',
                                        height: 150,
                                        borderRadius: 1,
                                        borderWidth: 0.1,
                                        borderColor: 'rgba(255, 255, 255, 1)',
                                        borderStyle: 'solid',
                                    }}
                                />

                                <p 
                                    style={{ 
                                        textAlign: 'left',
                                        fontSize: 12, 
                                        fontWeight: '500', 
                                        color: 'rgba(255, 255, 255, 1)', 
                                        marginTop: 25,
                                        marginLeft: 7,
                                        marginRight: 10,
                                    }}
                                >
                                    <b>
                                        Step 3: Accept or Reject File Submission 
                                    </b>
                                </p>
                                <div
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                    }}
                                >
                                    <Button 
                                        style={{
                                            backgroundColor: 'rgba(0, 158, 129, 1)',
                                            fontSize: 17,
                                            fontWeight: '600',
                                            color: 'rgba(255, 255, 255, 1)',
                                            padding: 5,
                                            borderRadius: 1,
                                            borderWidth: 0.1,
                                            borderColor: 'rgba(255, 255, 255, 1)',
                                            borderStyle: 'solid',
                                            marginTop: 5,
                                            marginRight: 10,
                                        }} 
                                        onMouseDown={this.toggleTouched} 
                                        onMouseUp={this.handleMouseUp} 
                                        onClick={() => { 
                                           
                                            that.acceptDocument()
                                        }}
                                    > 
                                        Accept Document
                                    </Button>
                                    <Button 
                                        style={{
                                            backgroundColor: 'rgba(204, 12, 12, 0.75)',
                                            fontSize: 17,
                                            fontWeight: '600',
                                            color: 'rgba(255, 255, 255, 1)',
                                            padding: 5,
                                            borderRadius: 1,
                                            borderWidth: 0.1,
                                            borderColor: 'rgba(255, 255, 255, 1)',
                                            borderStyle: 'solid',
                                            marginTop: 5,
                                            marginLeft: 10,
                                        }} 
                                        onMouseDown={this.toggleTouched} 
                                        onMouseUp={this.handleMouseUp} 
                                        onClick={() => { 
                                           
                                            that.rejectDocument()
                                        }}
                                    > 
                                        Reject Document
                                    </Button>
                                </div>
                            
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  </div>

                </div>
            </div>
        </div>
        );
    }

}

export default IC_PendingFileDocumentViewer;
