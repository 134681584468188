import Amplify, { Auth, Storage, API } from 'aws-amplify'
import './IC_PostAcceptanceFileDocumentViewer.css'; // Tell webpack that Button.js uses these styles
import React, {Component, Fragment, useRef} from 'react';
import awsconfig from '../aws-exports.js';
import { navigationHandler, routeProps } from '../utilities/navigator.js';
import LoadingIcons from 'react-loading-icons'
import {Buffer} from 'buffer';
import { tokenLifeTimeLimit, checkValidAuthTokenIntervalPeriod_ms,  LENDERBLOCKADMINWHITELIST} from '../blenderData/securityConfigurations';

import 'semantic-ui-css/semantic.min.css'
import { Button, Modal, Header, Image, } from 'semantic-ui-react'

var AWS = require('aws-sdk');

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

var checkValidAuthTokenInterval;

var cognitoID;
var currentUser;
var currentUserCap;
var stripeAccountID;
var stripeCustomerID;
var primaryDisplayCurrency;
var secondaryDisplayCurrency;
var publicKey;
var corporateBackedUsernamesArray;

let that;

let applicantUsername;

let monthLengtheningEnum = {
    JAN: 'January',
    FEB: 'February',
    MAR: 'March',
    APR: 'April',
    MAY: 'May',
    JUN: 'June',
    JUL: 'July',
    AUG: 'August',
    SEP: 'September',
    OCT: 'October',
    NOV: 'November',
    DEC: 'December',
}

class IC_PostAcceptanceFileDocumentViewer extends Component {

    constructor() {
        super()

        this.state = {
            usernameText: '',
            passwordText: '',
            touched: false,
            navigationDestinationObject: {
                navigationRoute: '',
            },
            button_recentApp_opacity: 1,
            button_pendingApp_opacity: 1,
            pendingApplicationsList: [],
            requestedAwaitingDocumentList: [],
            messageLineArray: [],
            submittedDocumentIDList: [],
            selectedDocumentData: [],              
            selectedDocumentTypeName: null,
            thisDocumentTypeDataMapArray: [],
            instancesSubmitted: null,
            privateReviewerNotes: null,
            showLoaderOverlay: false,
        };
        that = this

    }

    checkValidAuthToken(){
        let navigationDestinationObject = {
          navigationRoute: ''
        };
        
        Auth.currentAuthenticatedUser().then((res) => {
            if(res){
    
                currentUser = res.username
        
                if(!LENDERBLOCKADMINWHITELIST.includes(String(currentUser).toLowerCase())){
                    Auth.signOut()
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    }) 
                }
            } else {
                Auth.signOut()
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                }) 
            }
        }).catch((err) => {
            Auth.signOut()
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login',
                },
            }) 
        })
        
        
        checkValidAuthTokenInterval = setInterval(() => {
          Auth.currentAuthenticatedUser().then((res) => {
            if(res){
              let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
              let tokenLifetime = Date.now() - authTokenIssueTime
      
              if(tokenLifetime >= tokenLifeTimeLimit){
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                  return navigationHandler(navigationDestinationObject)
                }
              }
            } else {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                    return navigationHandler(navigationDestinationObject)
                }
            }
          }).catch(() => {
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login'
                }
            })
            if(navigationHandler(navigationDestinationObject)){
              return navigationHandler(navigationDestinationObject)
            }
          })
        }, checkValidAuthTokenIntervalPeriod_ms)
    }

    componentWillUnmount(){

    }
    componentDidMount(){
        that.checkValidAuthToken()
        
        that.initialMountComponents()

        applicantUsername = localStorage.getItem('applicantUsername')

            this.setState({
                usernameText: '',
                passwordText: '',
                touched: false,
                navigationDestinationObject: {
                    navigationRoute: '',
                },
                button_recentApp_opacity: 1,
                button_pendingApp_opacity: 1,
                pendingApplicationsList: [],
                requestedAwaitingDocumentList: [],
                messageLineArray: [],
                submittedDocumentIDList: [],
                selectedDocumentData: [],
                selectedDocumentTypeName: null,
                thisDocumentTypeDataMapArray: [],
                instancesSubmitted: null,
                privateReviewerNotes: null,
                showLoaderOverlay: true,
            })
            
            Auth.currentAuthenticatedUser().then((res) => {
                if(res){
                    let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
                    let tokenLifetime = Date.now() - authTokenIssueTime
                    if(tokenLifetime < tokenLifeTimeLimit){
                        currentUser = res.username
                        cognitoID = res.signInUserSession.idToken.jwtToken
                                        
                        currentUserCap = currentUser.toUpperCase()
                        if(currentUserCap.length > 10){
                            currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
                        }
                        
                        that.setState({
                            currentUser,
                            navigationDestinationObject: {
                                navigationRoute: '',
                            },
                        })

                    } else {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/login',
                            },
                        })
                    }
                } else {
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    })
                }
            
            }).catch((err) => {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                })
            })
    }

    toggleTouched = () => {
        this.setState( prevState => ({
        touched: !prevState.touched
        }));
    }
    
    handleMouseUp = () => {
        // Handle smooth animation when clicking without holding
        setTimeout( () => {
        this.setState({ touched: false });
        }, 150);
    }



    initialMountComponents(){

        Auth.currentAuthenticatedUser().then((res) => {
            currentUser = res.username
            cognitoID = res.signInUserSession.idToken.jwtToken

            AWS.config.update({region:'us-east-1'});
                            
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: 'us-east-1:8973ceec-5da3-423f-b3c6-e31ebdc08ce8',
                Logins: { 
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_vS08WURWh': cognitoID,
                }
            })

            AWS.config.credentials.refresh((error) => {
                if (error) {
                    return error
                }
            });

            currentUserCap = currentUser.toUpperCase()
            if(currentUserCap.length > 10){
                currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
            }
            that.setState({ currentUser })
            
            API.put('api1169ba1e', '', { 
                headers: { Authorization: `Bearer ${cognitoID}` },
                body: { 
                category: 'db',
                tableName: 'users', 
                primaryKey: String(applicantUsername), 
                requestOperation: 'get',
                }
            }).then((userData) => {
                
            
                API.put('api1169ba1e', '', { 
                  headers: { Authorization: `Bearer ${cognitoID}` },
                  body: { 
                      category: 'db',
                      tableName: 'growthDeveloperContractors', 
                      primaryKey: applicantUsername, 
                      requestOperation: 'get',
                  }
                }).then((growthDeveloperContractors) => {
                    let requestedAwaitingDocumentKeyArray = growthDeveloperContractors.Item.requestedAwaitingDocumentKeyArray
                    let requestedAwaitingDocumentKeyObjects = growthDeveloperContractors.Item.requestedAwaitingDocumentKeyObjects
                    let messageLineArray = growthDeveloperContractors.Item.messageLineArray
                
                    getRequestedDocuments()
                    
                    function getRequestedDocuments(){
                        let requestedAwaitingDocumentList = []
                        let skippedDocumentTypesNoAcceptedFilesCount = 0
                        // for(let r=0; r<=requestedAwaitingDocumentKeyArray.length-1; r++){
                        function requestedAwaitingDocumentKeyArrayLooper(r){

                            let thisDocumentTypeDataMapArray = []

                            if(r <= requestedAwaitingDocumentKeyArray.length-1){
                            
                                let thisTypeDocumentFileIDsList = requestedAwaitingDocumentKeyObjects[requestedAwaitingDocumentKeyArray[r]].documentFileIDs
                                let instancesSubmitted = requestedAwaitingDocumentKeyObjects[requestedAwaitingDocumentKeyArray[r]].instancesSubmitted
                                let privateReviewerNotes = requestedAwaitingDocumentKeyObjects[requestedAwaitingDocumentKeyArray[r]].privateReviewerNotes
                                let documentSatisfiedBool = requestedAwaitingDocumentKeyObjects[requestedAwaitingDocumentKeyArray[r]].documentSatisfiedBool

                                function loopTypeDocumentsFilesAccepted(l, r){

                                    if(thisTypeDocumentFileIDsList.length == 0){
                                        skippedDocumentTypesNoAcceptedFilesCount += 1

                                        that.setState({
                                            showLoaderOverlay: false,
                                        })
                                    }
                                    if(l <= thisTypeDocumentFileIDsList.length-1){
                                        let thisDocumentFileID = thisTypeDocumentFileIDsList[l]
                                        

                                        API.put('api1169ba1e', '', { 
                                            headers: { Authorization: `Bearer ${cognitoID}` },
                                            body: { 
                                                category: 'db',
                                                tableName: 'independentContractorDocumentFileIDMaps', 
                                                primaryKey: thisDocumentFileID, 
                                                requestOperation: 'get',
                                            }
                                        }).then((independentContractorDocumentFileIDMaps) => {
                                            if(independentContractorDocumentFileIDMaps.hasOwnProperty('Item')){


                                                const s3 = new AWS.S3();

                                                var getParams = {
                                                    Bucket: 'private-independent-contractor-document-submissions',                           
                                                    Key: `${applicantUsername}/${thisDocumentFileID}`  
                                                }
                        
                                                s3.getObject(getParams, async function(err, data) {
                                                    let base64Bytes = Buffer.from(data.Body).toString('base64');
                            
                                                    let dataType = data.ContentType
                            
                                                    let fileApprovalStatus = independentContractorDocumentFileIDMaps.Item.fileApprovalStatus
                                                    let username = independentContractorDocumentFileIDMaps.Item.username
                            
                                                    let documentSubmissionTimestamp = new Date(Number(thisDocumentFileID.substring(0,13)))
                                                    documentSubmissionTimestamp = that.formatNewDateObject(documentSubmissionTimestamp)
                            
                                                    // if(fileApprovalStatus.toLowerCase() == 'approved'){
                                                        thisDocumentTypeDataMapArray.push({
                                                            fileApprovalStatus,
                                                            username,
                                                            documentSubmissionTimestamp,
                                                            dataType,
                                                            documentFileID: thisDocumentFileID,
                                                            key: String(thisDocumentFileID).substring(0,13),
                                                            base64BytesImageSRC: String(`data:${dataType};base64,${String(base64Bytes)}`),
                                                        })
                                                    // } 


                                                    if(l == thisTypeDocumentFileIDsList.length-1){

                                                        requestedAwaitingDocumentList.push({
                                                            documentName: requestedAwaitingDocumentKeyArray[r],
                                                            key: String(Math.random()),
                                                            documentSatisfiedBool,
                                                            thisDocumentTypeDataMapArray,
                                                            privateReviewerNotes,
                                                            instancesSubmitted,
                                                        })

                                                        if(requestedAwaitingDocumentList.length-1 + skippedDocumentTypesNoAcceptedFilesCount == requestedAwaitingDocumentKeyArray.length-1){
                                                            that.setState({
                                                                requestedAwaitingDocumentList,
                                                                showLoaderOverlay: false,
                                                            })
                                                        }
                                                    } 

                                                })

                                            } else {
                                                loopTypeDocumentsFilesAccepted(l+1, r)
                                            }
                                        })
                                        loopTypeDocumentsFilesAccepted(l+1, r)
                                    }
                                }
                                loopTypeDocumentsFilesAccepted(0, r)
                
                                requestedAwaitingDocumentKeyArrayLooper(r+1)
                            }

                            if(requestedAwaitingDocumentKeyArray.length == 0){
                                that.setState({
                                    requestedAwaitingDocumentList,
                                    showLoaderOverlay: false,
                                })
                            }
                        }
                        requestedAwaitingDocumentKeyArrayLooper(0)
                    }
                })    
            })
        });
    }

    formatNewDateObject(documentSubmissionTimestamp){

      documentSubmissionTimestamp = String(documentSubmissionTimestamp)

      documentSubmissionTimestamp = monthLengtheningEnum[String(documentSubmissionTimestamp.substring(4,7)).toUpperCase()] + String(documentSubmissionTimestamp.substring(7,10)) + ',' + String(documentSubmissionTimestamp.substring(10,15))
      return documentSubmissionTimestamp;
    }

    render(){

        const { touched } = this.state;
        const className = touched ? 'button1_touched' : 'button1';

        applicantUsername = localStorage.getItem('applicantUsername')

        if(navigationHandler(that.state.navigationDestinationObject)){
            return navigationHandler(that.state.navigationDestinationObject)
        }

        return (
        <div className="IC_PostAcceptanceFileDocumentViewer">
            <React.Fragment>
                {
                    that.state.showLoaderOverlay
                    ?
                    <div
                        style={{
                            position: 'absolute',
                            // top: '2vh',
                            // right: '2vw',
                            // left: '2vw',
                            width: '100vw',
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                            padding: 10,
                            zIndex: 2000,
                        }}
                    >
                        <LoadingIcons.BallTriangle 
                            style={{
                                marginTop: '45vh',
                                marginBottom: '45vh',
                                marginRight: '42vw',
                                marginLeft: '42vw',
                            }} 
                            stroke="rgba(255, 255, 255, 1)" 
                            strokeWidth={1}
                            fill="rgba(0, 158, 129, 1)" 
                             
                        />
                    </div>
                    :
                    null
                }
            </React.Fragment>
            <div className="headerRowBox"> 
                <div 
                    onClick={() => {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/ic_allpendingapps',
                            }
                        }) 
                    }}
                >
                    <div className="logoBox"> 
                        <img className="logoImage" src="https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png"></img>
                        <p className="logoText"><b>THE BLENDER PLATFORM</b></p>
                    </div>
                </div>
                <div className="headerRowBoxNavBar">
                    
                  
                </div>
                <div className="headerUsernameBox"> 
                    <p style={{ textAlign: 'right', marginTop: 12.5, }} className="logoText"><b>
                        {/* <span style={{ textAlign: 'right', color: 'rgba(28, 28, 54, 1)' }}>
                            {'User Admin\n'}
                        </span> */}
                        {String(currentUser).toUpperCase()}
                    </b></p>
                    <Button
                        style={{
                        // height: 100,
                        padding: 10,
                        backgroundColor: 'rgba(204, 12, 12, 0)',
                        borderRadius: 3,
                        borderColor: 'rgba(255, 255, 255, 1)',
                        borderStyle: 'solid',
                        borderWidth: 0.5,
                        marginLeft: 25,
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 11, 
                        fontWeight: '600',
                        color: 'rgba(255, 255, 255, 1)',
                        }} 
                        onMouseDown={this.toggleTouched} 
                        onMouseUp={this.handleMouseUp} 
                        onClick={() => { 
                            Auth.signOut()
                            that.setState({
                                navigationDestinationObject: {
                                    navigationRoute: '/login',
                                },
                            }) 
                        }}
                    >
                        Sign Out
                    </Button>
                </div>
            </div>
            <div 
                style={{
                    backgroundColor:'#282c34',
                    width: '100%',
                    minHeight: '3000vh',
                    display: 'flex',
                    flexDirection: 'column',
                    /* align-items: center;
                    justify-content: center; */
                }}
            >
                <p 
                    style={{ 
                        position: 'absolute', 
                        top: 135, 
                        left: 25, 
                        color: 'rgba(0, 158, 129, 1)', 
                        fontSize: 18, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                        textUnderlineOffset: 10, 
                        textDecorationLine: 'underline', 
                        textDecorationColor: 'rgba(0, 158, 129, 1)'
                    }}
                >
                    Administrator Center
                    <span 
                        style={{ 
                            textAlign: 'left', 
                            fontSize: 16, 
                            fontWeight: '500', 
                            color: 'rgba(255, 255, 255, 1)', 
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'Independent Contractor Management'}
                    </span>
                </p>
                
                <Button
                    style={{
                    // height: 100,
                    padding: 10,
                    backgroundColor: 'rgba(204, 12, 12, 0)',
                    borderRadius: 3,
                    borderColor: 'rgba(255, 255, 255, 1)',
                    borderStyle: 'solid',
                    borderWidth: 0.5,
                    marginLeft: 25,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 11, 
                    fontWeight: '600',
                    color: 'rgba(255, 255, 255, 1)',

                    position: 'absolute', 
                    top: 205, 
                    left: 0, 
                    }} 
                    onMouseDown={this.toggleTouched} 
                    onMouseUp={this.handleMouseUp} 
                    onClick={() => {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/ic_appshow',
                            }
                        }) 
                    }}
                >
                    {'<<'} &nbsp; {'Contractor Application Review'}
                </Button>

                <div
                  style={{
                    listStyleType: 'none',
                    paddingBottom: 10,
                    paddingLeft: 10, 
                    paddingRight: 5,
                    position: 'absolute', 
                    top: 265, 
                    left: 25, 
                    width: '90vw',
                    // height: 500,
                    borderWidth: 0.5,
                    borderStyle: 'solid',
                    borderRadius: 3,
                    backgroundColor: 'rgba(0, 158, 129, 0.1)',
                    borderColor: 'rgba(0, 158, 129, 1)',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p 
                    style={{ 
                        // position: 'absolute', 
                        top: 5, 
                        marginLeft: 7, 
                        color: 'rgba(255, 255, 255, 1)', 
                        fontSize: 18, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                    }}
                  >
                    Accepted File Review
                  </p>
                  <p 
                    style={{ 
                        // position: 'absolute', 
                        top: 5, 
                        marginLeft: 7, 
                        color: 'rgba(255, 255, 255, 1)', 
                        fontSize: 15, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                    }}
                  >
                    {'Contractor Username: '} 
                    <span 
                        style={{ 
                            textAlign: 'left', 
                            fontSize: 15, 
                            fontWeight: '500', 
                            color: 'rgba(255, 255, 255, 1)', 
                          }}
                    >
                      <b>
                        {String(applicantUsername).toUpperCase()}
                      </b>
                    </span>
                  </p>

                  <div
                      style={{
                          // width: '45vw',
                          // height: 150,
                          borderRadius: 3,
                          borderWidth: 0.5,
                          paddingRight: 15,
                          // borderStyle: 'solid',
                          // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                          borderColor: 'rgba(0, 158, 129, 1)',
                          justifyContent: 'space-between',
                          alignItems: 'self-start',
                          marginTop: 20,
                          flexDirection: 'row',
                          display: 'flex',
                      }}
                  >
                    <React.Fragment>
                      <ul
                          style={{
                              listStyleType: 'none',
                              padding: 0,
                              // position: 'absolute', 
                              top: 265, 
                              left: 25, 
                              marginRight: 100,
                              // width: '45vw',
                              // height: 500,
                              // borderStyle: 'solid',
                              // borderRadius: 3,
                              // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                              // borderColor: 'rgba(0, 158, 129, 1)',
                              justifyContent: 'center',
                              alignItems: 'center',
                          }}
                      >
                        <p 
                          style={{ 
                            textAlign: 'left',
                            fontSize: 15, 
                            fontWeight: '500', 
                            color: 'rgba(0, 158, 129, 1)', 
                            marginTop: 5,
                            marginLeft: 7,
                            marginRight: 10,
                            textUnderlineOffset: 2, 
                            textDecorationLine: 'underline', 
                            textDecorationColor: 'rgba(0, 158, 129, 1)'
                          }}
                        >
                          <b>
                            Approved File Documents
                          </b>
                        </p>


                        {that.state.requestedAwaitingDocumentList.map(listitem => (
                            <li
                                onClick={() => {

                                    that.setState({
                                        thisDocumentTypeDataMapArray: listitem.thisDocumentTypeDataMapArray,
                                        selectedDocumentTypeName: listitem.documentName,
                                        instancesSubmitted: listitem.instancesSubmitted,
                                        privateReviewerNotes: listitem.privateReviewerNotes,
                                    })
                                   
                                }}
                                key={listitem.key}
                                style={{
                                    // width: '45vw',
                                    // height: 150,
                                    borderRadius: 3,
                                    borderWidth: 0.5,
                                    borderStyle: 'solid',
                                    backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                    borderColor: 'rgba(0, 158, 129, 0)',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: 20,
                                }}
                                >
                                <div 
                                    style={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        display: 'flex',
                                    }}
                                    // to={{
                                    //     pathname: '/ic_postfileviewer',
                                    //     // state: {
                                    //     //   applicantUsername: listitem.thisApplicantUsername.toLowerCase()
                                    //     // }
                                    // }}
                                >
                                    <p 
                                    style={{ 
                                        textAlign: 'left',
                                        fontSize: that.state.selectedDocumentTypeName == listitem.documentName ? 14 : 12, 
                                        fontWeight: 600, 
                                        color: 'rgba(255, 255, 255, 1)', 
                                        // marginTop: 5,
                                        marginLeft: 10,
                                        marginRight: 25,
                                    }}
                                    >
                                    {listitem.documentName} 
                                    </p>
                                    <p 
                                    style={{ 
                                        textAlign: 'left',
                                        fontSize: 10, 
                                        fontWeight: '600', 
                                        color: listitem.documentSatisfiedBool ? 'rgba(0, 158, 129, 1)' : 'rgba(204, 12, 12, 1)', 
                                        // marginTop: 5,
                                        marginRight: 10,
                                    }}
                                    >
                                        {listitem.documentSatisfiedBool ? 'APPR.' : 'DUE'} 
                                    </p>
                                </div>
                            </li>
                        ))}
                      </ul>
                    </React.Fragment>
                    <React.Fragment>
                      <ul
                          style={{
                              listStyleType: 'none',
                              padding: 0,
                              // position: 'absolute', 
                              top: 265, 
                              left: 25, 
                              width: '45vw',
                              // height: 500,
                              // borderStyle: 'solid',
                              // borderRadius: 3,
                              // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                              // borderColor: 'rgba(0, 158, 129, 1)',
                              justifyContent: 'center',
                              alignItems: 'center',
                          }}
                      >
                                              <p 
                          style={{ 
                            textAlign: 'left',
                            fontSize: 15, 
                            fontWeight: '500', 
                            color: 'rgba(0, 158, 129, 1)', 
                            marginTop: 5,
                            marginLeft: 7,
                            marginRight: 10,
                            textUnderlineOffset: 2, 
                            textDecorationLine: 'underline', 
                            textDecorationColor: 'rgba(0, 158, 129, 1)'
                          }}
                        >
                          <b>
                            {that.state.selectedDocumentTypeName ? `Accepted Files: ${that.state.selectedDocumentTypeName}` : ''}
                          </b>
                        </p>
                        <p 
                            style={{ 
                                textAlign: 'left',
                                fontSize: 13, 
                                fontWeight: '400', 
                                color: 'rgba(255, 255, 255, 1)', 
                                marginTop: 5,
                                marginLeft: 7,
                                marginRight: 10,
                          }}
                        >
                            <b>
                               {that.state.selectedDocumentTypeName ? `Document Type Approval Instances: ${that.state.instancesSubmitted}` : ''}
                            </b>
                        </p>
                        <p 
                            style={{ 
                                textAlign: 'left',
                                fontSize: 13, 
                                fontWeight: '400', 
                                color: 'rgba(255, 255, 255, 1)', 
                                marginTop: 5,
                                marginLeft: 7,
                                marginRight: 10,
                                marginBottom: 25,
                          }}
                        >
                            <b>
                               {that.state.selectedDocumentTypeName ? `Document Type Reviewer Notes:` : ''}
                            </b>
                            <span 
                                style={{ 
                                    textAlign: 'left', 
                                    fontSize: 14, 
                                    fontWeight: '400', 
                                    color: 'rgba(255, 255, 255, 1)', 
                                }}
                            >
                               {that.state.selectedDocumentTypeName ? ` ${that.state.privateReviewerNotes}` : ''}
                            </span>
                        </p>
                        {/* <p 
                          style={{ 
                            textAlign: 'left',
                            fontSize: 13, 
                            fontWeight: '400', 
                            color: 'rgba(255, 255, 255, 1)', 
                            marginTop: 5,
                            marginLeft: 7,
                            marginRight: 10,
                          }}
                        >
                            <b>
                               {that.state.selectedDocumentTypeName ? `Accepted Files Supporting: ${that.state.selectedDocumentTypeName}` : ''}
                            </b>
                        </p> */}


                        {that.state.thisDocumentTypeDataMapArray.map(listitem => (

                          <li
                            key={listitem.key}
                            style={{
                                // width: '45vw',
                                // height: 150,
                                // borderRadius: 3,
                                textAlign: 'left',
                                paddingBottom: 20,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0.1,
                                borderStyle: 'solid',
                                borderColor: 'rgba(0, 158, 129, 1)',
                                // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                // justifyContent: 'center',
                                // alignItems: 'center',
                                marginBottom: 20,
                            }}
                          >
                            <div 
                                style={{
                                    textAlign: 'left',
                                }}
                                onClick={() => {
                                    
                                }}

                            //   to={{
                            //       pathname: '/ic_postfileviewer',
                            //       // state: {
                            //       //   applicantUsername: listitem.thisApplicantUsername.toLowerCase()
                            //       // }
                            //   }}
                            >
                                <p 
                                    style={{ 
                                        textAlign: 'left',
                                        fontSize: 11, 
                                        fontWeight: '500', 
                                        color: 'rgba(255, 255, 255, 1)', 
                                        marginTop: 5,
                                        marginBottom: 10,
                                        marginLeft: 7,
                                        marginRight: 10,
                                    }}
                                >
                                    Submitted: {listitem.documentSubmissionTimestamp}
                                </p>
                                <p 
                                    style={{ 
                                    textAlign: 'left',
                                    fontSize: 11, 
                                    fontWeight: '500', 
                                    color: 'rgba(255, 255, 255, 1)', 
                                    marginTop: 5,
                                    marginBottom: 20,
                                    marginLeft: 7,
                                    marginRight: 10,
                                    }}
                                >
                                    File ID: {String(listitem.documentFileID).toUpperCase()}
                                </p>
                                {
                                    listitem.dataType == 'application/pdf'
                                    ?
                                    <embed 
                                        // onClick={() => {
                                        //     that.setState({
                                        //         selectedDocumentData: [{
                                        //             base64BytesImageSRC: listitem.base64BytesImageSRC,
                                        //             documentSubmissionTimestamp: listitem.documentSubmissionTimestamp,
                                        //             documentFileID: listitem.documentFileID,
                                        //             dataType: listitem.dataType,
                                        //         }]
                                        //     })
                                        // }}
                                        style={{
                                            marginLeft: 7,
                                            textAlign: 'left',
                                            maxHeight: 500,
                                            borderWidth: 0.5,
                                            borderStyle: 'solid',
                                            borderRadius: 1,
                                            borderColor: 'rgba(255, 255, 255, 1)',
                                        }}
                                        src={listitem.base64BytesImageSRC}
                                    />
                                    :
                                    <img 
                                        onClick={() => {
                                            // that.setState({
                                            //     selectedDocumentData: [{
                                            //         base64BytesImageSRC: listitem.base64BytesImageSRC,
                                            //         documentSubmissionTimestamp: listitem.documentSubmissionTimestamp,
                                            //         documentFileID: listitem.documentFileID,
                                            //         dataType: listitem.dataType,
                                            //     }]
                                            // })
                                        }}
                                        style={{
                                            marginLeft: 7,
                                            textAlign: 'left',
                                            right: '100%',
                                            maxHeight: 100,
                                            borderWidth: 0.5,
                                            borderStyle: 'solid',
                                            borderRadius: 1,
                                            borderColor: 'rgba(255, 255, 255, 1)',
                                        }}
                                        src={listitem.base64BytesImageSRC}
                                    />
                                }
                             
                            </div>
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>

                    
                  </div>

                </div>
            </div>
        </div>
        );
    }

}

export default IC_PostAcceptanceFileDocumentViewer;
