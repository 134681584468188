/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8973ceec-5da3-423f-b3c6-e31ebdc08ce8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vS08WURWh",
    "aws_user_pools_web_client_id": "5fv5ouctjclfd7r5nj9ol2la3b",
    "oauth": {},
    "aws_content_delivery_bucket": "blenderapp-20200305020018-hostingbucket-blenderapp",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dvb2t439bvzg2.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "contracts-blenderenv",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "private-user-verified-registry-document-submissions",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api1169ba1e",
            "endpoint": "https://u3q7rwpkf4.execute-api.us-east-1.amazonaws.com/blenderenv/items",
            "region": "us-east-1"
        },
        {
            "name": "api1169ba1e_authless",
            "endpoint": "https://29pv3s0m23.execute-api.us-east-1.amazonaws.com/blenderenv/items", 
            "region": "us-east-1"
        },
        {
            "name": "sendCollateralAssistanceToContract",
            "endpoint": "https://u2stmt9rjk.execute-api.us-east-1.amazonaws.com/blenderenv/items",
            "region": "us-east-1"
        },
        {
            "name": "triggerVerifiedRegistryUpload",
            "endpoint": " https://klovx8vzxj.execute-api.us-east-1.amazonaws.com/blenderenv/items",
            "region": "us-east-1"
        },
    ],
};


export default awsmobile;
