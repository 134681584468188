import Amplify, { Auth, Storage, API } from 'aws-amplify'
import './Login.css'; // Tell webpack that Button.js uses these styles
import React, {Component, Fragment} from 'react';
import awsconfig from '../aws-exports.js';
import { navigationHandler, routeProps } from '../utilities/navigator.js';
import 'semantic-ui-css/semantic.min.css'
import { Button, Modal, Header, Image, } from 'semantic-ui-react'
import { tokenLifeTimeLimit, checkValidAuthTokenIntervalPeriod_ms,  LENDERBLOCKADMINWHITELIST} from '../blenderData/securityConfigurations';

var AWS = require('aws-sdk');

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

var checkValidAuthTokenInterval;

let that;
let cognitoID;
var currentUser;
var currentUserCap;
var stripeAccountID;
var stripeCustomerID;
var primaryDisplayCurrency;
var secondaryDisplayCurrency;
var publicKey;
var corporateBackedUsernamesArray;


class Login extends Component {

    constructor() {
        super()

        this.state = {
            usernameText: '',
            passwordText: '',
            touched: false,
            navigationDestinationObject: {
                navigationRoute: '',
            },
            showLoaderOverlay: false,
        };
        that = this

    }

    componentDidMount(){
        Auth.signOut()

        const { navigation } = that.props;
        that.setState({
            usernameText: '',
            passwordText: '',
            touched: false,
            navigationDestinationObject: {
                navigationRoute: '',
            },
            failedLoginBool: false,
            showLoaderOverlay: false,
        })

    }

      
    toggleTouched = () => {
        this.setState( prevState => ({
        touched: !prevState.touched
        }));
    }
    
    handleMouseUp = () => {
        // Handle smooth animation when clicking without holding
        setTimeout( () => {
        this.setState({ touched: false });
        }, 150);
    }

    loginUser(){

        if(that.state.usernameText.length > 0 && that.state.passwordText.length > 0){
            if(LENDERBLOCKADMINWHITELIST.includes(String(that.state.usernameText).replace(/\s/g, '').toLowerCase())){

                Auth.signIn(String(that.state.usernameText).toLowerCase(), that.state.passwordText)
                    .then((res) => {
                        if(LENDERBLOCKADMINWHITELIST.includes((that.state.usernameText).replace(/\s/g, '').toLowerCase())){
                        

                            Auth.currentAuthenticatedUser().then((res) => {
                                cognitoID = res.signInUserSession.idToken.jwtToken 

                                AWS.config.update({region:'us-east-1'});
                                
                                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                                    IdentityPoolId: 'us-east-1:8973ceec-5da3-423f-b3c6-e31ebdc08ce8',
                                    Logins: { 
                                        'cognito-idp.us-east-1.amazonaws.com/us-east-1_vS08WURWh': cognitoID,
                                    }
                                })

                                AWS.config.credentials.refresh((error) => {
                                    if (error) {
                                        return error
                                    }
                                });


                                that.setState({
                                    navigationDestinationObject: {
                                        navigationRoute: '/home',
                                    },
                                })

                            })
                        } else {
                            that.setState({ 
                                failedLoginBool: true
                            })
                        }
                    }).catch((err) => {
                        
                        that.setState({
                            failedLoginBool: true
                        })
                    })
            } else {

                that.setState({
                    failedLoginBool: true
                })
            }
        }
    }
    

    render(){
        const { touched } = this.state;
        let className = touched ? 'button1_touched' : 'button1';
        
        if(that.state.failedLoginBool){
            className = 'button1_red'
        }

        if(navigationHandler(that.state.navigationDestinationObject)){
            return navigationHandler(that.state.navigationDestinationObject)
        }

        return (
        <div className="Login">
            {/* <h1>App.js page</h1> */}
            <div className="App-header">
               
                <br/><br/><br/><br/>
                <div 
                    style={{
                        backgroundColor: 'rgba(0, 158, 129, 1)',
                        borderRadius: 8,
                        borderWidth: 2,
                        borderColor: 'rgba(255, 255, 255, 1)',
                        borderStyle: 'solid',
                        height: 400,
                        width: 350,
                        /* display: flex;
                        flex-direction: column; */
                        alignItems: 'center',
                        justifyContent: 'center',
                        /* font-size: calc(10px + 2vmin); */
                        color: 'rgba(255, 255, 255, 1)',
                    }}
                > 
                    <div 
                        style={{
                            backgroundColor: 'rgba(0, 158, 129, 1)',
                            opacity: 1,
                            borderRadius: 8,
                            // borderWidth: 2,
                            // borderColor: 'rgba(255, 255, 255, 1)',
                            // borderStyle: 'solid',
                            margin: 25, 
                            height: 175,
                            width: 300,
                            
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: 20,
                            color: 'white'
                        }}
                    > 
                        <img 
                            style={{
                                width: 105,
                                height: 105,
                                borderRadius: 8,
                                borderWidth: 2,
                                marginLeft: 25,
                                marginRight: 25,
                                borderColor: 'rgba(255, 255, 255, 1)',
                                borderStyle: 'solid',
                            }}
                            src="https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png"
                        >
                        </img>
                        <p 
                            style={{
                                fontSize: 28,
                                textAlign: 'left',
                                color: 'rgba(255, 255, 255, 1)',
                                textShadowColor: 'rgba(28, 28, 54, 1)',
                                textShadowRadius: 10,
                                textShadowOffset: {
                                    width: 2,
                                    height: 2
                                },
                            }}
                        >
                            <b>
                                The Blender Platform
                            </b>
                        </p>
                    </div>
                    <input 
                        type="username" 
                        id="usernameText" 
                        // className="loginInput"  
                        value={that.state.usernameText}
                        onChange={(event) => {
                            that.setState({
                                failedLoginBool: false,
                                usernameText: event.target.value
                            })
                        }} 
                        name="text"
                        placeholder='Username'
                        style={{
                            fontWeight: '600',
                            color: 'rgba(0, 158, 129, 1)',
                            paddingLeft: 10,
                            paddingRight: 10,
                            textAlign: 'center',
                            fontSize: 16,
                            marginTop: 15,
                            width: 235,
                            height: 45,
                            borderRadius: 6,
                            borderWidth: 2,
                            borderColor: 'rgba(255, 255, 255, 1)',
                            borderStyle: 'solid',
                        }}
                    />
                    <input 
                        type="password"
                        id="passwordText" 
                        // className="loginInput"  
                        value={that.state.passwordText}
                        onChange={(event) => {
                            that.setState({
                                failedLoginBool: false,
                                passwordText: event.target.value
                            })
                        }} 
                        name="passwordText"
                        placeholder='Password'
                        style={{
                            fontWeight: '600',
                            color: 'rgba(0, 158, 129, 1)',
                            paddingLeft: 10,
                            paddingRight: 10,
                            textAlign: 'center',
                            fontSize: 16,
                            marginTop: 15,
                            width: 235,
                            height: 45,
                            borderRadius: 6,
                            borderWidth: 2,
                            borderColor: 'rgba(255, 255, 255, 1)',
                            borderStyle: 'solid',
                        }}
                    />
                    <br/>
                </div>
                <br/><br/><br/>
                <button className={className} onMouseDown={this.toggleTouched} onMouseUp={this.handleMouseUp} onClick={() => { that.loginUser() }}>Log In</button>
                <br/><br/><br/>
                <div 
                    href='https://lenderblock.io'
                >
                    <a
                        href='https://lenderblock.io'
                        style={{
                            textDecoration: 'none',
                            backgroundColor: 'none',
                        }}
                    >
                        <p 
                            style={{
                                fontSize: 12,
                                textAlign: 'center',
                                // color: 'rgba(0, 158, 129, 1)',
                                fontWeight: '500',
                                color: 'rgba(255, 255, 255, 1)',
                            }}
                        >
                            Product of Lenderblock Corporation
                        </p>
                    </a>
                </div>
            </div>
        </div>
        );
    }
}

export default Login;
