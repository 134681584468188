
import Amplify, { Auth, Storage, API } from 'aws-amplify'
import './WalletBalances.css'; // Tell webpack that Button.js uses these styles
import React, {Component, Fragment, useRef} from 'react';
import awsconfig from '../aws-exports.js';
import { navigationHandler, routeProps } from '../utilities/navigator.js';
import LoadingIcons from 'react-loading-icons'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { tokenLifeTimeLimit, checkValidAuthTokenIntervalPeriod_ms,  LENDERBLOCKADMINWHITELIST} from '../blenderData/securityConfigurations';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
  } from 'chart.js';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import 'semantic-ui-css/semantic.min.css';
import { Button, Modal, Header, Image, } from 'semantic-ui-react';
import { Link } from 'react-router-dom';



var AWS = require('aws-sdk');

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

var checkValidAuthTokenInterval;

var cognitoID;
var currentUser;
var currentUserCap;
var stripeAccountID;
var stripeCustomerID;
var primaryDisplayCurrency;
var secondaryDisplayCurrency;
var publicKey;
var corporateBackedUsernamesArray;
let proposeGasPrice;
var currentPage;

var campaign;
let client;
const secretName = "PrivateBlenderKeys"
const region = 'us-east-1'


let applicantUsername;

let that;

let monthLengtheningEnum = {
    JAN: 'January',
    FEB: 'February',
    MAR: 'March',
    APR: 'April',
    MAY: 'May',
    JUN: 'June',
    JUL: 'July',
    AUG: 'August',
    SEP: 'September',
    OCT: 'October',
    NOV: 'November',
    DEC: 'December',
}

let rand = () => Math.floor(Math.random() * 20) - 10;

let currentPendingApplicantUsernames = []

// const Backdrop = styled("div")`
//   position: fixed;
//   z-index: 1040;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-color: #000;
//   opacity: 0.5;
// `;

// // we use some pseudo random coords so nested modals
// // don't sit right on top of each other.
// const RandomlyPositionedModal = styled(Modal)`
//   position: fixed;
//   width: 400px;
//   z-index: 1040;
//   top: ${() => 50 + rand()}%;
//   left: ${() => 50 + rand()}%;
//   border: 1px solid #e5e5e5;
//   background-color: white;
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
//   padding: 20px;
// `;

const renderBackdrop = () => 
    <div style={{ 
        position: 'fixed',
        zIndex: 1040,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#000',
        opacity: 0.5
    }}/>

class WalletBalances extends Component {

    constructor() {
        super()

        this.state = {
            usernameText: '',
            passwordText: '',
            touched: false,
            navigationDestinationObject: {
                navigationRoute: '',
            },
            button_recentApp_opacity: 1,
            button_pendingApp_opacity: 1,
            showLoaderOverlay: false,
            walletMapList: [],
            walletBalanceHistory: [],
            walletAddress : null,
            nextPageParams: null,
        };
        that = this
    }

    checkValidAuthToken(){
        let navigationDestinationObject = {
          navigationRoute: ''
        };
        
        Auth.currentAuthenticatedUser().then((res) => {
            if(res){
    
                currentUser = res.username
        
                if(!LENDERBLOCKADMINWHITELIST.includes(String(currentUser).toLowerCase())){
                    Auth.signOut()
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    }) 
                }
            } else {
                Auth.signOut()
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                }) 
            }
        }).catch((err) => {
            Auth.signOut()
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login',
                },
            }) 
        })
        
        
        checkValidAuthTokenInterval = setInterval(() => {
          Auth.currentAuthenticatedUser().then((res) => {
            if(res){
              let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
              let tokenLifetime = Date.now() - authTokenIssueTime
      
              if(tokenLifetime >= tokenLifeTimeLimit){
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                  return navigationHandler(navigationDestinationObject)
                }
              }
            } else {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                    return navigationHandler(navigationDestinationObject)
                }
            }
          }).catch(() => {
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login'
                }
            })
            if(navigationHandler(navigationDestinationObject)){
              return navigationHandler(navigationDestinationObject)
            }
          })
        }, checkValidAuthTokenIntervalPeriod_ms)
    }

    componentWillUnmount(){

    }
    componentDidMount(){
        that.checkValidAuthToken()

        that.initialMountComponents()

        applicantUsername = localStorage.getItem('applicantUsername')

            this.setState({
                usernameText: '',
                passwordText: '',
                touched: false,
                navigationDestinationObject: {
                    navigationRoute: '',
                },
                button_recentApp_opacity: 1,
                button_pendingApp_opacity: 1,
                walletMapList: []
            })
            
            Auth.currentAuthenticatedUser().then((res) => {
                if(res){
                    let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
                    let tokenLifetime = Date.now() - authTokenIssueTime
                    if(tokenLifetime < tokenLifeTimeLimit){
                        currentUser = res.username
                        cognitoID = res.signInUserSession.idToken.jwtToken
                                        
                        currentUserCap = currentUser.toUpperCase()
                        if(currentUserCap.length > 10){
                            currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
                        }
                        
                        that.setState({
                            currentUser,
                            navigationDestinationObject: {
                                navigationRoute: '',
                            },
                        })
                    } else {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/login',
                            },
                        })
                    }
                } else {
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    })
                }
            
            }).catch((err) => {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                })
            })
    }

    toggleTouched = () => {
        this.setState( prevState => ({
        touched: !prevState.touched
        }));
    }
    
    handleMouseUp = () => {
        // Handle smooth animation when clicking without holding
        setTimeout( () => {
        this.setState({ touched: false });
        }, 150);
    }
    

    getWalletBalanceHistory(walletAddress, next=false, clear = true){
        let thisURL = String(`https://gnosis.blockscout.com/api/v2/addresses/${walletAddress}/coin-balance-history${next ? `?page=$&` : ''}`)
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        try{
        fetch(thisURL, {
            mode: 'cors',
            // credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(response => response.json())
            .then((data) => {
                this.setState({
                    walletBalanceHistory: clear ? [...data.items] : [...data.items,...this.state.walletBalanceHistory],
                    nextPageParams: data.next_page_params
                })
                console.log(data)
            }).catch((err) => {
                console.log(err)
            })
        } catch (err) {
            console.log(err)
        }
    }

    initialMountComponents(){
        let walletMapList = [
            {
                walletAddress: '0x3B897fabfd614644B7A970CeE12B83b5C4Ff686d',
                buttonTitle: 'PRIVATE PROXY Wallet\n',
                walletBalance: "-",
                additionalWalletText: "",
            },
            {
                walletAddress: '0x7f8782482eE24CD08e95973e0DE7e8EA7d177FD9',
                buttonTitle: 'PRIVATE TRANSFERS OUT Wallet\n',
                walletBalance: "-",
                additionalWalletText: "",
            },
            {
                walletAddress: '0x3cF203cD256eaD38b2488F23B61387019d88436a',
                buttonTitle: 'PRIVATE PROMOTION-TRANSFERS OUT Wallet',
                walletBalance: "-",
                additionalWalletText: "",
            },
            {
                walletAddress: '0x308296910feC066BF5539C44d5D6EeC1De55099F',
                buttonTitle: 'PRIVATE New HOLDING Wallet\n',
                walletBalance: "-",
                additionalWalletText: "",
            },
            {
                walletAddress: '0x2b6b3a794e6bB6296466a284Bc8e92d74E13304B',
                buttonTitle: 'PRIVATE TRANSFERS IN Wallet\n',
                walletBalance: "-",
                additionalWalletText: "",
            },
            {
                walletAddress: '0x08C07D889727CFfEFF54aad0eBCd48A461700b58',
                buttonTitle: 'Deployment New Smart Contract Wallet',
                walletBalance: "-",
                additionalWalletText: "",
            },
            {
                walletAddress: '0xb44685d88de0b5EA1f9bDf1c9bA69a7A9e9789fE',
                buttonTitle: 'PUBLIC New Blender General Public and Private Keys (hardcoded)',
                walletBalance: "-",
                additionalWalletText: "",
            },
            { 
                walletAddress: '0xb69ee2d67956b4fD5fefA62354E61ed506dbCBa8',
                buttonTitle: 'PRIVATE MASTER Permissions Factory Contract CONFIGURE Wallet',
                walletBalance: "-",
                additionalWalletText: "",
            }
        ]

        

        function getWalletBalances(w){
            setTimeout(() => {

                if(w<=walletMapList.length-1){
                    let thisWalletAddress = walletMapList[w].walletAddress
                    let balanceWEI = 0;
                    let thisURL = String(`https://api.gnosisscan.io/api?module=account&action=balance&address=${thisWalletAddress}&tag=latest&apikey=VJ8T8J3JXCZ2GD35PSVJGE3GKRUB95SURK`)
                    
                    let headers = new Headers();
                    headers.append('Content-Type', 'application/json');
                    headers.append('Accept', 'application/json');
                    // headers.append('Origin','http://localhost:3000');

                    try{
                    fetch(thisURL, {
                        mode: 'cors',
                        // credentials: 'include',
                        method: 'POST',
                        headers: headers
                    })
                        .then(response => response.json())
                        .then((data) => {
                            balanceWEI = parseFloat(Number(data.result), 10)/1000000000000000000
                            balanceWEI = balanceWEI.toFixed(3)

                            walletMapList[w].walletBalance = balanceWEI

                            that.setState({
                                walletMapList
                            })
                            getWalletBalances(w+1)
                        }).catch((err) => {
                            getWalletBalances(w+1)
                        })
                    } catch (err) {
                        getWalletBalances(w+1)
                    }
                    
                } else {
                    that.setState({
                        walletMapList
                    })
                }

            }, 500)
        }
        getWalletBalances(0)
    }


    render(){
        
        const { touched } = this.state;
        const className = touched ? 'button1_touched' : 'button1';

        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            TimeScale,
          );
        const options = {
            scales: {
                x: {
                    type: 'time',
                    
                },
                y: {
                    title: {
                        display: true,
                        text: 'Value in xDAI (USD)'
                    }
                }
            },
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Walet Balance',
              },
            },
          };
        

        applicantUsername = localStorage.getItem('applicantUsername')

        if(navigationHandler(that.state.navigationDestinationObject)){
            return navigationHandler(that.state.navigationDestinationObject)
        }



        return (
        <div className="WalletBalances">

            <React.Fragment>
                {
                    that.state.showLoaderOverlay
                    ?
                    <div
                        style={{
                            position: 'absolute',
                            // top: '2vh',
                            // right: '2vw',
                            // left: '2vw',
                            width: '100vw',
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                            padding: 10,
                            zIndex: 2000,
                        }}
                    >
                        <LoadingIcons.BallTriangle 
                            style={{
                                marginTop: '45vh',
                                marginBottom: '45vh',
                                marginRight: '42vw',
                                marginLeft: '42vw',
                            }} 
                            strokeWidth={1}
                            stroke="rgba(255, 255, 255, 1)" 
                            fill="rgba(0, 158, 129, 1)" 
                             
                        />
                    </div>
                    :
                    null
                }
            </React.Fragment>



            <div className="headerRowBox"> 
                <div
                    onClick={() => {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/home',
                            }
                        }) 
                    }}
                >
                    <div className="logoBox"> 
                        <img className="logoImage" src="https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png"></img>
                        <p className="logoText"><b>THE BLENDER PLATFORM</b></p>
                    </div>
                </div>
                <div className="headerRowBoxNavBar">
                    
                  
                </div>
                <div className="headerUsernameBox"> 
                    <p style={{ textAlign: 'right', marginTop: 12.5, }} className="logoText"><b>
                        {/* <span style={{ textAlign: 'right', color: 'rgba(28, 28, 54, 1)' }}>
                            {'User Admin\n'}
                        </span> */}
                        {String(currentUser).toUpperCase()}
                    </b></p>
                    <Button
                        className='buttonStyle'
                        style={{
                        padding: 10,
                        marginLeft: 25,
                        }} 
                        onMouseDown={this.toggleTouched} 
                        onMouseUp={this.handleMouseUp} 
                        onClick={() => { 
                            Auth.signOut()
                            that.setState({
                                navigationDestinationObject: {
                                    navigationRoute: '/login',
                                }
                            }) 
                        }}
                    >
                        Sign Out
                    </Button>
                </div>
            </div>
            <div 
                style={{
                    backgroundColor:'#282c34',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    /* align-items: center;
                    justify-content: center; */
                }}
            >
                <p 
                    style={{ 
                        position: 'absolute', 
                        top: 135, 
                        left: 25, 
                        color: 'rgba(0, 158, 129, 1)', 
                        fontSize: 18, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                        textUnderlineOffset: 10, 
                        textDecorationLine: 'underline', 
                        textDecorationColor: 'rgba(0, 158, 129, 1)'
                    }}
                >
                    Administrator Center
                    <span 
                        style={{ 
                            textAlign: 'left', 
                            fontSize: 16, 
                            fontWeight: '500', 
                            color: 'rgba(255, 255, 255, 1)', 
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'Lenderblock Wallet Balance Maintenance'}
                    </span>
                </p>

                <Button
                    className='buttonStyle'
                    style={{
                    padding: 10,
                    marginLeft: 25,
                    position: 'absolute', 
                    top: 205, 
                    left: 0, 
                    }} 
                    onMouseDown={this.toggleTouched} 
                    onMouseUp={this.handleMouseUp} 
                    onClick={() => {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/home',
                            }
                        }) 
                    }}
                >
                    {'<<'} &nbsp; {'Administrator Center Home'}
                </Button>


                <div style={{display: 'flex', flexDirection: 'row', height: '100%', width: '100%' , paddingTop:235, paddingLeft:25}}>
                    <ul
                        style={{
                            listStyleType: 'none',
                            padding: 0, 
                            width: '45vw',
                            // height: 500,
                            // borderStyle: 'solid',
                            borderRadius: 3,
                            backgroundColor: 'rgba(0, 158, 129, 0)',
                            borderColor: 'rgba(0, 158, 129, 1)',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <p 
                            style={{ 
                                // position: 'absolute', 
                                marginTop: 20, 
                                color: 'rgba(255, 255, 255, 1)', 
                                fontSize: 17, 
                                fontWeight: '600', 
                                textAlign: 'left',
                                textShadowColor: 'rgba(255, 255, 255, 1)',
                                textShadowOffset: {width: 10, height: 10},
                                textShadowRadius: 10,  
                            }}
                        >
                            Lenderblock Wallet Balances
                        </p>

                        {that.state.walletMapList.map(listitem => (

                            <li
                                key={listitem.walletAddress}
                                style={{
                                    // width: '35vw',
                                    // height: 150,
                                    // borderRadius: 3,
                                    // borderWidth: 0.5,
                                    // borderStyle: 'solid',
                                    // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                    // borderColor: 'rgba(0, 158, 129, 1)',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    marginRight: 25,
                                    float: 'left',
                                    // padding: 5, 
                                    marginTop: 20,
                                }}
                            >
                                <Button
                                    className='green-clear'
                                    onClick={() => {
                                        currentPage = 1
                                        this.getWalletBalanceHistory(listitem.walletAddress)
                                        this.setState({
                                            walletAddress: listitem.walletAddress
                                        })
                                    }}
                                    text={listitem.walletAddress}
                                    style={{
                                        width: '35vw',
                                        marginRight: 25,
                                        float: 'left',
                                        padding: 5, 
                                        marginTop: 20,
                                    }}
                                >
                                    <div>
                                        <p 
                                            style={{ 
                                                textAlign: 'left',
                                                fontSize: 14, 
                                                fontWeight: '500', 
                                                color: 'rgba(255, 255, 255, 1)', 
                                                marginTop: 10,
                                                marginLeft: 5,
                                            }}
                                        ><b>
                                            {`${listitem.buttonTitle.toUpperCase()}`}
                                        </b></p>
                                        <p 
                                            style={{ 
                                                textAlign: 'left',
                                                fontSize: 15, 
                                                fontWeight: '600',  
                                                color: 'rgba(255, 255, 255, 1)',  
                                                marginTop: 25,
                                                marginLeft: 5,
                                                // textUnderlineOffset: 3, 
                                                // textDecorationLine: 'underline', 
                                                // textDecorationColor: 'rgba(255, 255, 255, 1)',
                                            }}
                                        >
                                        {`${listitem.walletBalance} xDAI (USD)`}
                                        </p>
                                        <p 
                                            style={{ 
                                                textAlign: 'left',
                                                fontSize: 10, 
                                                fontWeight: '500', 
                                                color: 'rgba(255, 255, 255, 1)', 
                                                marginTop: 15,
                                                marginLeft: 5,
                                                // textUnderlineOffset: 3, 
                                                // textDecorationLine: 'underline', 
                                                // textDecorationColor: 'rgba(255, 255, 255, 1)',
                                            }}
                                        >
                                            {listitem.walletAddress}
                                        </p>
                                    </div>
                                </Button>
                            </li>
                        ))}
                    </ul>

                    <div style={{width: '50%', paddingTop:60}}>
                        
                           {that.state.walletBalanceHistory.length > 0 && 
                            <Line
                                options={options}
                                data={{
                                    datasets: [{
                                        label: `${this.state.walletAddress}`,
                                        data: this.state.walletBalanceHistory.map((item, index) => {
                                            return {
                                                x: item.block_timestamp,
                                                y: item.value / (10**18)
                                            }
                                        }),
                                        fill: false,
                                        borderColor: 'rgb(75, 192, 192)',
                                        tension: 0.1
                                    }]
                                } }
                            />
                           }
                           {this.state.walletAddress &&
                           <>
                           <Button 
                                className='buttonStyle'
                                style={{
                                padding: 10,
                                marginLeft: 25,
                                }} 
                                onClick={() => {
                                    this.getWalletBalanceHistory(this.state.walletAddress, currentPage++, true)
                                }}>Previous</Button>
                            <Button 
                                className='buttonStyle'
                                style={{
                                padding: 10,
                                marginLeft: 25,
                                }} 
                                onClick={() => {
                                window.open(`https://gnosis.blockscout.com/address/${this.state.walletAddress}`, "_blank", "noreferrer");
                            }}>go to website</Button>
                           </>
                            
                           }
                        
                    </div>
                </div>

            </div>
        
        </div>
        );
    }

}

export default WalletBalances;
