import Amplify, { Auth, Storage, API } from 'aws-amplify'
import './IC_ApplicationShow.css'; // Tell webpack that Button.js uses these styles
import React, {Component, Fragment, useRef} from 'react';
import awsconfig from '../aws-exports.js';
import { navigationHandler, routeProps } from '../utilities/navigator.js';
import LoadingIcons from 'react-loading-icons'
import {Buffer} from 'buffer';
import { tokenLifeTimeLimit, checkValidAuthTokenIntervalPeriod_ms,  LENDERBLOCKADMINWHITELIST} from '../blenderData/securityConfigurations';

import 'semantic-ui-css/semantic.min.css'
import { Button, Modal, Header, Image, } from 'semantic-ui-react'

var AWS = require('aws-sdk');

var mimemessage = require('mimemessage');

var checkValidAuthTokenInterval;

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

var cognitoID;
var currentUser;
var currentUserCap;
var stripeAccountID;
var stripeCustomerID;
var primaryDisplayCurrency;
var secondaryDisplayCurrency;
var publicKey;
var corporateBackedUsernamesArray;

let that;

let applicantUsername;

let monthLengtheningEnum = {
    JAN: 'January',
    FEB: 'February',
    MAR: 'March',
    APR: 'April',
    MAY: 'May',
    JUN: 'June',
    JUL: 'July',
    AUG: 'August',
    SEP: 'September',
    OCT: 'October',
    NOV: 'November',
    DEC: 'December',
}

class IC_ApplicationShow extends Component {

    constructor() {
        super()

        this.state = {
            usernameText: '',
            passwordText: '',
            touched: false,
            navigationDestinationObject: {
              navigationRoute: '',
            },
            button_recentApp_opacity: 1,
            button_pendingApp_opacity: 1,
            pendingApplicationsList: [],
            requestedAwaitingDocumentList: [],
            messageLineArray: [],
            submittedDocumentIDList: [],
            ableToFinalizeAndApproveBool: false,
            clientCorrespondenceMsgTextString: '',
            applicantUserFirstName: null,
            showLoaderOverlay: false,
            overheadApplicationApprovalString: '',
            applicantUserFirstName: '',
            applicantEmailAddress: '',
            applicantUsername: '',
        };
        that = this

    }

    checkValidAuthToken(){
      let navigationDestinationObject = {
        navigationRoute: ''
      };
        
      Auth.currentAuthenticatedUser().then((res) => {
        if(res){

            currentUser = res.username
    
            if(!LENDERBLOCKADMINWHITELIST.includes(String(currentUser).toLowerCase())){
                Auth.signOut()
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                }) 
            }
        } else {
            Auth.signOut()
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login',
                },
            }) 
        }
    }).catch((err) => {
        Auth.signOut()
        that.setState({
            navigationDestinationObject: {
                navigationRoute: '/login',
            },
        }) 
    })
      
      
      checkValidAuthTokenInterval = setInterval(() => {
        Auth.currentAuthenticatedUser().then((res) => {
          if(res){
            let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
            let tokenLifetime = Date.now() - authTokenIssueTime
    
            if(tokenLifetime >= tokenLifeTimeLimit){
              that.setState({
                  navigationDestinationObject: {
                      navigationRoute: '/login'
                  }
              })
              if(navigationHandler(navigationDestinationObject)){
                return navigationHandler(navigationDestinationObject)
              }
            }
          } else {
              that.setState({
                  navigationDestinationObject: {
                      navigationRoute: '/login'
                  }
              })
              if(navigationHandler(navigationDestinationObject)){
                  return navigationHandler(navigationDestinationObject)
              }
          }
        }).catch(() => {
          that.setState({
              navigationDestinationObject: {
                  navigationRoute: '/login'
              }
          })
          if(navigationHandler(navigationDestinationObject)){
            return navigationHandler(navigationDestinationObject)
          }
        })
      }, checkValidAuthTokenIntervalPeriod_ms)
  }

  componentWillUnmount(){

  }
    componentDidMount(){
      that.checkValidAuthToken()
      
      that.initialMountComponents()

      applicantUsername = localStorage.getItem('applicantUsername')

      this.setState({
        usernameText: '',
        passwordText: '',
        touched: false,
        navigationDestinationObject: {
          navigationRoute: '',
        },
        button_recentApp_opacity: 1,
        button_pendingApp_opacity: 1,
        pendingApplicationsList: [],
        requestedAwaitingDocumentList: [],
        messageLineArray: [],
        submittedDocumentIDList: [],
        ableToFinalizeAndApproveBool: false,
        clientCorrespondenceMsgTextString: '',
        applicantUserFirstName: null,
        showLoaderOverlay: true,
        overheadApplicationApprovalString: '',
        applicantUserFirstName: '',
        applicantEmailAddress: '',
        applicantUsername: '',
      })
      
      Auth.currentAuthenticatedUser().then((res) => {
          if(res){
            let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
            let tokenLifetime = Date.now() - authTokenIssueTime
            if(tokenLifetime < tokenLifeTimeLimit){
                currentUser = res.username
                cognitoID = res.signInUserSession.idToken.jwtToken
                        
                currentUserCap = currentUser.toUpperCase()
                if(currentUserCap.length > 10){
                    currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
                }
                
                that.setState({
                    currentUser,
                    navigationDestinationObject: {
                        navigationRoute: '',
                    },
                })
            } else {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                })
            }
          } else {
              that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login',
                },
              })
          }
      
      }).catch((err) => {
          that.setState({
            navigationDestinationObject: {
                navigationRoute: '/login',
            },
          })
      })
    }

    toggleTouched = () => {
        this.setState( prevState => ({
        touched: !prevState.touched
        }));
    }
    
    handleMouseUp = () => {
        // Handle smooth animation when clicking without holding
        setTimeout( () => {
        this.setState({ touched: false });
        }, 150);
    }



    initialMountComponents(){

        
        Auth.currentAuthenticatedUser().then((res) => {
            currentUser = res.username
            cognitoID = res.signInUserSession.idToken.jwtToken

            AWS.config.update({region:'us-east-1'});
                            
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: 'us-east-1:8973ceec-5da3-423f-b3c6-e31ebdc08ce8',
                Logins: { 
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_vS08WURWh': cognitoID,
                }
            })

            AWS.config.credentials.refresh((error) => {
                if (error) {
                    return error
                }
            });


            currentUserCap = currentUser.toUpperCase()
            if(currentUserCap.length > 10){
                currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
            }
            that.setState({ currentUser })
                        
            API.put('api1169ba1e', '', { 
                headers: { Authorization: `Bearer ${cognitoID}` },
                body: { 
                category: 'db',
                tableName: 'users', 
                primaryKey: String(applicantUsername), 
                requestOperation: 'get',
                }
            }).then((userData) => {
                let applicantUserFirstName = userData.Item.firstName
                applicantUserFirstName = `${applicantUserFirstName.substring(0,1).toUpperCase()}${applicantUserFirstName.substring(1,applicantUserFirstName.length).toLowerCase()}`
                let applicantEmailAddress = userData.Item.emailAddress

                that.setState({
                  applicantUserFirstName,
                  applicantEmailAddress,
                  applicantUsername
                })

                API.put('api1169ba1e', '', { 
                  headers: { Authorization: `Bearer ${cognitoID}` },
                  body: { 
                      category: 'db',
                      tableName: 'growthDeveloperContractors', 
                      primaryKey: applicantUsername, 
                      requestOperation: 'get',
                  }
                }).then((growthDeveloperContractors) => {
                  let requestedAwaitingDocumentKeyArray = growthDeveloperContractors.Item.requestedAwaitingDocumentKeyArray
                  let requestedAwaitingDocumentKeyObjects = growthDeveloperContractors.Item.requestedAwaitingDocumentKeyObjects
                  let messageLineArray = growthDeveloperContractors.Item.messageLineArray
                  let applicationStatus = growthDeveloperContractors.Item.applicationStatus

                  that.setState({
                    overheadApplicationApprovalString: String(applicationStatus).toLowerCase() == 'activated' ? 'Active Independent Contractor' : null 
                  })
                  if(String(messageLineArray[0].messageText).length == 0){
                    messageLineArray.splice(0,1)
                  }
                  messageLineArray = messageLineArray.reverse();

                  let allDocumentFileIDs = growthDeveloperContractors.Item.allDocumentFileIDs
                  let adminReviewUpdates = growthDeveloperContractors.Item.adminReviewUpdates
                  let lastReviewedDocumentID = adminReviewUpdates.lastViewedDocumentID

                  let submittedDocumentIDList = []

                  function loopSubmittedDocumentIDs(d){
                  // for(let d=allDocumentFileIDs.length-1; d>=0; d--){
                    if(d >= 0){
                      let thisDocumentFileID = allDocumentFileIDs[d]
                      let _d = d

                      API.put('api1169ba1e', '', { 
                        headers: { Authorization: `Bearer ${cognitoID}` },
                        body: { 
                            category: 'db',
                            tableName: 'independentContractorDocumentFileIDMaps', 
                            primaryKey: thisDocumentFileID, 
                            requestOperation: 'get',
                        }
                      }).then((independentContractorDocumentFileIDMaps) => {

                      
                        
                        const s3 = new AWS.S3();

                        var getParams = {
                            Bucket: 'private-independent-contractor-document-submissions',                           
                            Key: `${applicantUsername}/${thisDocumentFileID}`  
                        }

                        s3.getObject(getParams, async function(err, data) {
                          let base64Bytes = Buffer.from(data.Body).toString('base64');

                          let dataType = data.ContentType

                          let fileApprovalStatus = independentContractorDocumentFileIDMaps.Item.fileApprovalStatus
                          let username = independentContractorDocumentFileIDMaps.Item.username

                          let documentSubmissionTimestamp = new Date(Number(thisDocumentFileID.substring(0,13)))
                          documentSubmissionTimestamp = that.formatNewDateObject(documentSubmissionTimestamp)

                          if(Number(String(thisDocumentFileID).substring(0,13)) > Number(String(lastReviewedDocumentID).substring(0,13)) && fileApprovalStatus.toLowerCase() == 'pending'){
                            submittedDocumentIDList.push({
                              fileApprovalStatus,
                              username,
                              documentSubmissionTimestamp,
                              dataType,
                              key: String(thisDocumentFileID).substring(0,13),
                              base64BytesImageSRC: String(`data:${dataType};base64,${String(base64Bytes)}`)
                            })
                            if(_d == 0){
                              getRequestedDocuments()
                            } else {
                              loopSubmittedDocumentIDs(d-1)
                            }
                          } else {
                            if(_d == 0){
                              getRequestedDocuments()
                            } else {
                              loopSubmittedDocumentIDs(d-1)
                            }
                          }
                        })
                      })
                    }
                  }
                  loopSubmittedDocumentIDs(allDocumentFileIDs.length-1)

                  function getRequestedDocuments(){
                    let ableToFinalizeAndApproveBool = true
                    let requestedAwaitingDocumentList = []
                    for(let r=0; r<=requestedAwaitingDocumentKeyArray.length-1; r++){


                      if(ableToFinalizeAndApproveBool){
                        ableToFinalizeAndApproveBool = requestedAwaitingDocumentKeyObjects[requestedAwaitingDocumentKeyArray[r]].documentSatisfiedBool
                      }
                      
                      requestedAwaitingDocumentList.push({
                        documentName: requestedAwaitingDocumentKeyArray[r],
                        documentSatisfiedBool: requestedAwaitingDocumentKeyObjects[requestedAwaitingDocumentKeyArray[r]].documentSatisfiedBool,
                        key: String(Math.random()),
                      })

                      if(r == requestedAwaitingDocumentKeyArray.length-1){
                        
                        that.setState({
                          requestedAwaitingDocumentList,
                          messageLineArray,
                          submittedDocumentIDList,
                          ableToFinalizeAndApproveBool,
                          showLoaderOverlay: false,
                        })

                      }
                    }
                  }
                })    
            })
        });
    }

    sendEmailFunction(subjectString, addresseeUsername, addresseeFirstName, recipientEmail, customBodyString){

      var mailContent_signer = mimemessage.factory({contentType: 'multipart/mixed',body: []});
      mailContent_signer.header('From', 'The Blender Platform <theblenderplatform@lenderblock.io>');
      mailContent_signer.header('To', recipientEmail);
      mailContent_signer.header('Subject', subjectString);

      let bodyStringHTML = String(`<div style="text-align: right;">` + addresseeUsername.toUpperCase() + `</div><div><strong>Hello ` + addresseeFirstName + `,</strong></div><div>&nbsp;</div><div>${customBodyString}</div><div>&nbsp;</div><div>Happy Lending and Borrowing!</div><div>The Blender Platform</div><div>&nbsp;</div><div><img src="https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png" alt="TBP&quot;" width="77" height="77" /></div><div>&nbsp;</div><div>&nbsp;</div>`)

      var alternateEntity_signer = mimemessage.factory({
         contentType: 'multipart/alternate',
         body: []
      });

      function emailBodyGenerator(){
          return (
              mimemessage.factory({
                  contentType: 'text/html;charset=utf-8',
                  body: bodyStringHTML
              })
          );
      }

      var plainEntity_signer = emailBodyGenerator()
   
      alternateEntity_signer.body.push(plainEntity_signer);
      
      mailContent_signer.body.push(alternateEntity_signer);

      var ses = new AWS.SES();

      ses.sendRawEmail({
         Destinations: [recipientEmail],
         RawMessage: {
            Data: mailContent_signer.toString() 
         },
         Source: 'theblenderplatform@lenderblock.io', // Must be verified within AWS SE
      }, (res) => {

      })
    }

    addMemberToVerifiedRegistry(){
      window.scrollTo(0, 0)
      that.setState({
        showLoaderOverlay: true
      })
      
      API.put('api1169ba1e', '', { 
        headers: { Authorization: `Bearer ${cognitoID}` },
        body: { 
            category: 'db',
            tableName: 'growthDeveloperContractors', 
            primaryKey: applicantUsername, 
            requestOperation: 'update',
            updateExpression: `SET 
              applicationStatus = :applicationStatus
            `, 
            expressionAttributeValues: { 
              ':applicationStatus': 'activated'
            }
        }
      }).then((res) => {
        that.initialMountComponents()

        let globalDateString = String(new Date()).substr(4,11)
        globalDateString = globalDateString.substr(0,3) + '.' + globalDateString.substr(3,3) + ',' + globalDateString.substr(6,5);
  
        let subjectString = 'Verified Registry Status - Acceptance Confirmed'
        let emailBodyString = String(
`<div>
<div>
The Blender Platform Verified Registry Review Team has approved and accepted your Verified Registry Application and all documents which you have submitted for verification.
</div>
<div>&nbsp;</div>
<div>
You may now Request Blender Collateral Assistance within Credit-Lines you create within The Blender Platform, which (if granted) serves to increase the Credit-Limit of the corresponding Credit-Lines for your increased borrowing volume. 
</div>
<div>&nbsp;</div>
<div>
Important Notices: 
</div>
<div>&nbsp;</div>
<div>
- Acceptance to the Verified Registry does not guarantee Collateral Assistance Disbursement.
</div>
<div>
- The Verified Registry Review Team may request more information from you over time to continually maintain your acceptance. 
</div>
<div>
- Information collected via the Verified Registry may be utilized in debt collection efforts in the ill-case of your delinquency or failure to repay owed funds to a Credit-Line in which you are the borrower.
</div>
<div>&nbsp;</div>
<div>
Visit your Credit-Lines within The Blender Platform to begin your request for Collateral Assistance.
</div>
</div>
`
        )
  
        that.sendEmailFunction(subjectString, that.state.applicantUsername.toUpperCase(), that.state.applicantUserFirstName, that.state.applicantEmailAddress, emailBodyString)          
      })
    }

    expelMemberFromVerifiedRegistry(){
      window.scrollTo(0, 0)
      that.setState({
        showLoaderOverlay: true
      })

      API.put('api1169ba1e', '', { 
        headers: { Authorization: `Bearer ${cognitoID}` },
        body: { 
            category: 'db',
            tableName: 'growthDeveloperContractors', 
            primaryKey: applicantUsername, 
            requestOperation: 'update',
            updateExpression: `SET 
              applicationStatus = :applicationStatus
            `, 
            expressionAttributeValues: { 
              ':applicationStatus': 'unactivated'
            }
        }
      }).then((res) => {
        that.initialMountComponents()

        let globalDateString = String(new Date()).substr(4,11)
        globalDateString = globalDateString.substr(0,3) + '.' + globalDateString.substr(3,3) + ',' + globalDateString.substr(6,5);

        let subjectString = 'Verified Registry Status - Revocation Alert'
        let emailBodyString = String(
`<div>
<div>
The Blender Platform Verified Registry Review Team has revoked your Verified Registry Acceptance.
</div>
<div>&nbsp;</div>
<div>
Revocations can occur due to:  
</div>
<div>&nbsp;</div>
<div>
- Newly Required or Outdated Documents Submissions
</div>
<div>
- Public Complaints Received Regarding your Contractual Work
</div>
<div>
- Independent Contractor Agreement Termination
</div>
<div>
- Other Causes
</div>
<div>&nbsp;</div>
<div>
For more information and closure, please contact the Lenderblock Independent Contractor Team Team within The Blender Platform Application.
</div>
</div>
`
        )

        that.sendEmailFunction(subjectString, that.state.applicantUsername.toUpperCase(), that.state.applicantUserFirstName, that.state.applicantEmailAddress, emailBodyString)       
      })   
    }

    sendCorrespondenceMessage(){
      if(String(that.state.clientCorrespondenceMsgTextString).length > 1){
        API.put('api1169ba1e', '', { 
          headers: { Authorization: `Bearer ${cognitoID}` },
          body: { 
              category: 'db',
              tableName: 'growthDeveloperContractors', 
              primaryKey: applicantUsername, 
              requestOperation: 'update',
              updateExpression: `SET 
                messageLineArray = list_append(messageLineArray, :messageLineArray)
              `, 
              expressionAttributeValues: { 
                ':messageLineArray': [{
                  messageText: that.state.clientCorrespondenceMsgTextString,
                  senderUsername: `${that.state.applicantUserFirstName} (ADMIN)`,
                  timestamp: String(new Date())
                }]
              }
          }
        }).then((res) => {
            that.setState({
              clientCorrespondenceMsgTextString: ''
            })
            that.initialMountComponents()
        })
      } else {

      }
    }

    formatNewDateObject(documentSubmissionTimestamp){

      documentSubmissionTimestamp = String(documentSubmissionTimestamp)

      let clockTimeString = `${Number(String(documentSubmissionTimestamp).substr(16,2)) <= 12 ? Number(String(documentSubmissionTimestamp).substr(16,2)) : Number(String(Number(String(documentSubmissionTimestamp).substr(16,2)) - 12))}${String(documentSubmissionTimestamp).substr(18,3)} ${Number(String(documentSubmissionTimestamp).substr(16,2)) < 12 ? 'AM' : 'PM'}`

      documentSubmissionTimestamp = clockTimeString + ' - ' + String(documentSubmissionTimestamp.substring(4,7)) + '.' + String(documentSubmissionTimestamp.substring(7,10)) + ',' + String(documentSubmissionTimestamp.substring(10,15))
      return documentSubmissionTimestamp;
    }

    render(){
        applicantUsername = localStorage.getItem('applicantUsername')
     
        const { touched } = this.state;
        const className = touched ? 'button1_touched' : 'button1';

        if(navigationHandler(that.state.navigationDestinationObject)){
            return navigationHandler(that.state.navigationDestinationObject)
        }

        return (
        <div className="IC_ApplicationShow">
            <React.Fragment>
              {
                  that.state.showLoaderOverlay
                  ?
                  <div
                      style={{
                          position: 'absolute',
                          // top: '2vh',
                          // right: '2vw',
                          // left: '2vw',
                          width: '100vw',
                          height: '100vh',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(255, 255, 255, 0.25)',
                          padding: 10,
                          zIndex: 2000,
                      }}
                  >
                      <LoadingIcons.BallTriangle 
                          style={{
                              marginTop: '45vh',
                              marginBottom: '45vh',
                              marginRight: '42vw',
                              marginLeft: '42vw',
                          }} 
                          strokeWidth={1}
                          stroke="rgba(255, 255, 255, 1)" 
                          fill="rgba(0, 158, 129, 1)" 
                           
                      />
                  </div>
                  :
                  null
              }
            </React.Fragment>
            <div className="headerRowBox"> 
                <div 
                    onClick={() => {
                      that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/home',
                        }
                      }) 
                    }}
                >
                    <div className="logoBox"> 
                        <img className="logoImage" src="https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png"></img>
                        <p className="logoText"><b>THE BLENDER PLATFORM</b></p>
                    </div>
                </div>
                <div className="headerRowBoxNavBar">
                  
                </div>
                <div 
                  className="headerUsernameBox"
                > 
                    <p style={{ textAlign: 'right', marginTop: 12.5, }} className="logoText"><b>
                        {/* <span style={{ textAlign: 'right', color: 'rgba(28, 28, 54, 1)' }}>
                            {'User Admin\n'}
                        </span> */}
                        {String(currentUser).toUpperCase()}
                    </b></p>
                    <Button
                        style={{
                        // height: 100,
                        padding: 10,
                        backgroundColor: 'rgba(204, 12, 12, 0)',
                        borderRadius: 3,
                        borderColor: 'rgba(255, 255, 255, 1)',
                        borderStyle: 'solid',
                        borderWidth: 0.5,
                        marginLeft: 25,
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 11, 
                        fontWeight: '600',
                        color: 'rgba(255, 255, 255, 1)',
                        }} 
                        onMouseDown={this.toggleTouched} 
                        onMouseUp={this.handleMouseUp} 
                        onClick={() => { 
                            Auth.signOut()
                            that.setState({
                              navigationDestinationObject: {
                                  navigationRoute: '',
                              },
                            }) 
                        }}
                    >
                        Sign Out
                    </Button>
                </div>
               
            </div>
            <div 
                style={{
                    backgroundColor:'#282c34',
                    width: '100%',
                    minHeight: '3000vh',
                    display: 'flex',
                    flexDirection: 'column',
                    /* align-items: center;
                    justify-content: center; */
                }}
            >
                <p 
                    style={{ 
                        position: 'absolute', 
                        top: 135, 
                        left: 25, 
                        color: 'rgba(0, 158, 129, 1)', 
                        fontSize: 18, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                        textUnderlineOffset: 10, 
                        textDecorationLine: 'underline', 
                        textDecorationColor: 'rgba(0, 158, 129, 1)'
                    }}
                >
                    Administrator Center
                    <span 
                        style={{ 
                            textAlign: 'left', 
                            fontSize: 16, 
                            fontWeight: '500', 
                            color: 'rgba(255, 255, 255, 1)', 
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'Independent Contractor Management'}
                    </span>
                </p>

                <Button
                  style={{
                    // height: 100,
                    padding: 10,
                    backgroundColor: 'rgba(204, 12, 12, 0)',
                    borderRadius: 3,
                    borderColor: 'rgba(255, 255, 255, 1)',
                    borderStyle: 'solid',
                    borderWidth: 0.5,
                    marginLeft: 25,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 11, 
                    fontWeight: '600',
                    color: 'rgba(255, 255, 255, 1)',

                    position: 'absolute', 
                    top: 205, 
                    left: 0, 
                  }} 
                  onClick={() => {
                      that.setState({
                          navigationDestinationObject: {
                              navigationRoute: '/ic_allpendingapps',
                          }
                      }) 
                  }}
                  onMouseDown={this.toggleTouched} 
                  onMouseUp={this.handleMouseUp} 
                >
                  {'<<'} &nbsp; {'All Pending Applications'}
                </Button>

                <div
                  style={{
                    listStyleType: 'none',
                    paddingBottom: 10,
                    paddingLeft: 10, 
                    paddingRight: 5,
                    position: 'absolute', 
                    top: 265, 
                    left: 25, 
                    width: '90vw',
                    // height: 500,
                    borderWidth: 0.5,
                    borderStyle: 'solid',
                    borderRadius: 3,
                    backgroundColor: 'rgba(0, 158, 129, 0.1)',
                    borderColor: 'rgba(0, 158, 129, 1)',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      // width: '45vw',
                      // height: 150,
                      borderRadius: 3,
                      borderWidth: 0.5,
                      paddingRight: 15,
                      // borderStyle: 'solid',
                      // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                      borderColor: 'rgba(0, 158, 129, 1)',
                      justifyContent: 'space-between',
                      alignItems: 'self-start',
                      flexDirection: 'row',
                      display: 'flex',
                      paddingRight: 25,
                    }}
                  > 
                    <div
                      style={{
                          // width: '45vw',
                          // height: 150,
                          borderRadius: 3,
                          borderWidth: 0.5,
                          paddingRight: 15,
                          // borderStyle: 'solid',
                          // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                          borderColor: 'rgba(0, 158, 129, 1)',
                          justifyContent: 'space-between',
                          alignItems: 'self-start',
                          // flexDirection: 'row',
                          // display: 'flex',
                          paddingRight: 25,
                      }}
                    >
                      <p 
                        style={{ 
                            // position: 'absolute', 
                            top: 5, 
                            marginLeft: 7, 
                            color: 'rgba(255, 255, 255, 1)', 
                            fontSize: 18, 
                            fontWeight: '600', 
                            textAlign: 'left',
                            textShadowColor: 'rgba(255, 255, 255, 1)',
                            textShadowOffset: {width: 10, height: 10},
                            textShadowRadius: 10,  
                        }}
                      >
                        Independent Contractor Application Review
                      </p>
                      <p 
                        style={{ 
                            // position: 'absolute', 
                            top: 5, 
                            marginLeft: 7, 
                            color: 'rgba(255, 255, 255, 1)', 
                            fontSize: 15, 
                            fontWeight: '600', 
                            textAlign: 'left',
                            textShadowColor: 'rgba(255, 255, 255, 1)',
                            textShadowOffset: {width: 10, height: 10},
                            textShadowRadius: 10,  
                        }}
                      >
                        {'Contractor Username: '} 
                        <span 
                            style={{ 
                                textAlign: 'left', 
                                fontSize: 15, 
                                fontWeight: '500', 
                                color: 'rgba(255, 255, 255, 1)', 
                              }}
                        >
                          <b>
                            {String(applicantUsername).toUpperCase()}
                          </b>
                        </span>
                      </p>
                      <p 
                        style={{ 
                            // position: 'absolute', 
                            top: 5, 
                            marginLeft: 7, 
                            color: 'rgba(255, 255, 255, 1)', 
                            fontSize: 15, 
                            fontWeight: '600', 
                            textAlign: 'left',
                            textShadowColor: 'rgba(255, 255, 255, 1)',
                            textShadowOffset: {width: 10, height: 10},
                            textShadowRadius: 10,  
                            textUnderlineOffset: 2, 
                            textDecorationLine: 'underline', 
                            textDecorationColor: 'rgba(255, 255, 255, 1)',
                        }}
                      >
                        {that.state.overheadApplicationApprovalString} 
                      </p>
                    </div>
                    <div
                      style={{
                          // width: '45vw',
                          // height: 150,
                          borderRadius: 3,
                          borderWidth: 0.5,
                          // borderStyle: 'solid',
                          paddingTop: 20,
                          // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                          borderColor: 'rgba(0, 158, 129, 1)',
                          justifyContent: 'space-between',
                          alignItems: 'self-start',
                          flexDirection: 'row',
                          display: 'flex',
                      }}
                    >
                    
                      <Button
                        style={{
                          // height: 100,
                          padding: 8,
                          marginLeft: 10,
                          backgroundColor: 'rgba(0, 158, 129, 1)',
                          borderRadius: 1,
                          borderColor: 'rgba(255, 255, 255, 1)',
                          borderStyle: 'solid',
                          borderWidth: 0.5,
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 14, 
                          fontWeight: '600',
                          color: 'rgba(255, 255, 255, 1)',
                        }} 
                        onMouseDown={this.toggleTouched} 
                        onMouseUp={this.handleMouseUp} 
                        onClick={() => { 
                          that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/ic_pendingfileviewer',
                            }
                          }) 
                        }}
                      >
                        Review Pending File Submissions
                      </Button>

                      <Button
                        style={{
                          // height: 100,
                          padding: 8,
                          marginLeft: 10,
                          backgroundColor: 'rgba(0, 158, 129, 1)',
                          borderRadius: 1,
                          borderColor: 'rgba(255, 255, 255, 1)',
                          borderStyle: 'solid',
                          borderWidth: 0.5,
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 14, 
                          fontWeight: '600',
                          color: 'rgba(255, 255, 255, 1)',
                        }} 
                        onMouseDown={this.toggleTouched} 
                        onMouseUp={this.handleMouseUp} 
                        onClick={() => { 
                          that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/ic_postfileviewer',
                            }
                          }) 
                        }}
                      >
                        View Accepted File Submissions
                      </Button>
                    </div>
                  </div>
                  <div
                    style={{
                        // width: '45vw',
                        // height: 150,
                        borderRadius: 3,
                        borderWidth: 0.5,
                        paddingRight: '25vw',
                        // borderStyle: 'solid',
                        // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                        borderColor: 'rgba(0, 158, 129, 1)',
                        justifyContent: 'space-between',
                        alignItems: 'self-start',
                        marginTop: 20,
                        flexDirection: 'row',
                        display: 'flex',
                        paddingRight: 25,
                    }}
                  >
                    <React.Fragment>
                      <ul
                          style={{
                              listStyleType: 'none',
                              padding: 0,
                              // position: 'absolute', 
                              top: 265, 
                              left: 25, 
                              // width: '45vw',
                              // height: 500,
                              // borderStyle: 'solid',
                              // borderRadius: 3,
                              // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                              // borderColor: 'rgba(0, 158, 129, 1)',
                              justifyContent: 'center',
                              alignItems: 'center',
                          }}
                      >
                        <p 
                          style={{ 
                            textAlign: 'left',
                            fontSize: 15, 
                            fontWeight: '500', 
                            color: 'rgba(0, 158, 129, 1)', 
                            marginTop: 5,
                            marginLeft: 7,
                            marginRight: 10,
                            textUnderlineOffset: 2, 
                            textDecorationLine: 'underline', 
                            textDecorationColor: 'rgba(0, 158, 129, 1)'
                          }}
                        >
                          <b>
                            Document Submission Statuses
                          </b>
                        </p>
                        {that.state.requestedAwaitingDocumentList.map(listitem => (

                          <li
                            key={listitem.key}
                            style={{
                                // width: '45vw',
                                // height: 150,
                                borderRadius: 3,
                                borderWidth: 0.5,
                                borderStyle: 'solid',
                                backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                borderColor: 'rgba(0, 158, 129, 0)',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 20,
                            }}
                          >
                            <div 
                              style={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'row',
                                display: 'flex',
                              }}
                            >
                              <p 
                                style={{ 
                                  textAlign: 'left',
                                  fontSize: 12, 
                                  fontWeight: '600', 
                                  color: 'rgba(255, 255, 255, 1)', 
                                  // marginTop: 5,
                                  marginLeft: 10,
                                  marginRight: 25,
                                }}
                              >
                                {listitem.documentName} 
                              </p>
                              <p 
                                style={{ 

                                  textAlign: 'left',
                                  fontSize: 10, 
                                  fontWeight: '600', 
                                  color: listitem.documentSatisfiedBool ? 'rgba(0, 158, 129, 1)' : 'rgba(247, 90, 90, 1)', 
                                  // marginTop: 5,
                                  marginRight: 10,
                                }}
                              >
                                {listitem.documentSatisfiedBool ? 'APPR.' : 'DUE'} 
                              </p>
                            </div>
                          </li>
                        ))}
                        <Button
                          disabled={!that.state.ableToFinalizeAndApproveBool}
                          style={{
                            // height: 100,
                            padding: 8,
                            marginLeft: 7,
                            marginTop: 50,
                            marginRight: 50,
                            backgroundColor: that.state.ableToFinalizeAndApproveBool ? 'rgba(0, 158, 129, 1)' : 'rgba(0, 158, 129, 0.1)', 
                            borderRadius: 1,
                            borderColor: that.state.ableToFinalizeAndApproveBool ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.1)',
                            borderStyle: 'solid',
                            borderWidth: 0.5,
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 14, 
                            fontWeight: '600',
                            color: that.state.ableToFinalizeAndApproveBool ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.25)',
                          }} 
                          onMouseDown={this.toggleTouched} 
                          onMouseUp={this.handleMouseUp} 
                          onClick={() => { 
                          
                            that.addMemberToVerifiedRegistry()
                          }}
                        >
                          Finalize & Accept Independent Contractor
                        </Button>
                        <Button
                          // disabled={!that.state.ableToFinalizeAndApproveBool}
                          disabled={true}
                          style={{
                            // height: 100,
                            padding: 8,
                            marginLeft: 7,
                            marginTop: 50,
                            marginRight: 50,
                            backgroundColor: that.state.ableToFinalizeAndApproveBool ? 'rgba(204, 12, 12, 0.1)' : 'rgba(204, 12, 12, 0.1)', 
                            borderRadius: 1,
                            borderColor: that.state.ableToFinalizeAndApproveBool ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.1)',
                            borderStyle: 'solid',
                            borderWidth: 0.5,
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 14, 
                            fontWeight: '600',
                            color: that.state.ableToFinalizeAndApproveBool ? 'rgba(255, 255, 255, 0.25)' : 'rgba(255, 255, 255, 0.25)',
                          }} 
                          onMouseDown={this.toggleTouched} 
                          onMouseUp={this.handleMouseUp} 
                          onClick={() => { 
                          
                            that.expelMemberFromVerifiedRegistry()
                          }}
                        >
                          Reject Independent Contractor
                        </Button>
                      </ul>
                    </React.Fragment>
                    <div
                      style={{
                        width: '5vw'
                      }}
                    />
                    <React.Fragment>
                      <ul
                          style={{
                              listStyleType: 'none',
                              padding: 0,
                              // position: 'absolute', 
                              top: 265, 
                              left: 25, 
                              // width: '45vw',
                              // height: 500,
                              // borderStyle: 'solid',
                              // borderRadius: 3,
                              // backgroundColor: 'rgba(0, 158, 129, 0.1)',
                              // borderColor: 'rgba(0, 158, 129, 1)',
                              justifyContent: 'center',
                              alignItems: 'center',
                          }}
                      >
                        <p 
                          style={{ 
                            textAlign: 'left',
                            fontSize: 15, 
                            fontWeight: '500', 
                            color: 'rgba(0, 158, 129, 1)', 
                            marginTop: 5,
                            marginLeft: 7,
                            marginRight: 10,
                            textUnderlineOffset: 2, 
                            textDecorationLine: 'underline', 
                            textDecorationColor: 'rgba(0, 158, 129, 1)'
                          }}
                        >
                          <b>
                            Contractor Message Correspondence
                          </b>
                        </p>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <textarea 
                            type="text" 
                            id="text" 
                            // className="loginInput"  
                            value={that.state.clientCorrespondenceMsgTextString}
                            onChange={(event) => {
                                that.setState({
                                  clientCorrespondenceMsgTextString: event.target.value
                                })
                            }} 
                            name="text"
                            placeholder={`Send ${String(applicantUsername).toUpperCase()} a Correspondence Message`}
                            style={{
                                fontWeight: '500',
                                fontFamily: 'arial',
                                color: 'rgba(0, 0, 0, 1)',
                                padding: 5,
                                textAlign: 'left',
                                textAlignVertical: 'center',
                                fontSize: 14,
                                marginTop: 5,
                                width: '38vw',
                                height: 150,
                                borderRadius: 1,
                                borderWidth: 0.1,
                                borderColor: 'rgba(255, 255, 255, 1)',
                                borderStyle: 'solid',
                            }}
                          />
                          <Button 
                            style={{
                              backgroundColor: 'rgba(0, 158, 129, 1)',
                              fontSize: 15,
                              fontWeight: '600',
                              color: 'rgba(255, 255, 255, 1)',
                              padding: 5,
                              borderRadius: 1,
                              borderWidth: 0.1,
                              borderColor: 'rgba(255, 255, 255, 1)',
                              borderStyle: 'solid',
                              marginTop: 15,
                            }} 
                            onMouseDown={this.toggleTouched} 
                            onMouseUp={this.handleMouseUp} 
                            onClick={() => { 
                              that.sendCorrespondenceMessage()
                            }}
                          > 
                            Send Correspondence Message to Contractor
                          </Button>
                          <p 
                            style={{ 
                              textAlign: 'left',
                              fontSize: 10, 
                              fontWeight: '500', 
                              color: 'rgba(0, 158, 129, 1)', 
                              marginTop: 25,
                              marginBottom: 15,
                              marginLeft: 7,
                              marginRight: 10,
                            }}
                          >
                            <b>
                              Recent Message Thread
                            </b>
                          </p>
                        </div>
                        <div
                          style={{
                            borderRadius: 1,
                            borderWidth: 0.1,
                            borderStyle: 'solid',
                            borderColor: 'rgba(0, 158, 129, 1)',
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            // marginTop: 20,
                            // padding: 3,
                          }}
                        >
                          {that.state.messageLineArray.map((listitem, mapIndex) => (
                            <li
                              key={listitem.timestamp}
                              style={{
                                  // width: '45vw',
                                  // height: 150,
                                  // borderRadius: 3,
                                  // borderWidth: 0.5,
                                  // borderStyle: 'solid',
                                  maxWidth: '55vw',
                                  backgroundColor: 'rgba(255, 255, 255, 1)',
                                  // borderColor: 'rgba(0, 158, 129, 1)',
                                  // justifyContent: 'center',
                                  // alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: 1,
                                  borderWidth: 0.1,
                                  borderStyle: 'solid',
                                  borderColor: 'rgba(0, 158, 129, 1)',
                                  backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                  marginTop: mapIndex == 0 ? 0 : 5,
                                }}
                              >
                                <p 
                                  style={{ 
                                    textAlign: 'left',
                                    fontSize: 11, 
                                    fontWeight: '600', 
                                    color: 'rgba(0, 0, 0, 1)', 
                                    marginTop: 5,
                                    marginLeft: 7,
                                    marginRight: 10,
                                  }}
                                >
                                  {listitem.messageText}
                                </p>
                                <p 
                                  style={{ 
                                    textAlign: 'right',
                                    fontSize: 10, 
                                    fontWeight: '600', 
                                    color: listitem.senderUsername.toLowerCase() == applicantUsername ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 1)', 
                                    marginTop: 5,
                                    marginLeft: 7,
                                    marginRight: 10,
                                  }}
                                >
                                  - {String(listitem.senderUsername).toUpperCase()}
                                </p>
                                <p 
                                  style={{ 
                                    textAlign: 'right',
                                    fontSize: 10, 
                                    fontWeight: '600', 
                                    color: 'rgba(0, 0, 0, 1)', 
                                    marginTop: 0,
                                    marginLeft: 7,
                                    marginRight: 10,
                                  }}
                                >
                                  {that.formatNewDateObject(listitem.timestamp)}
                                </p>
                              </div>
                            </li>
                          ))}
                        </div>
                      </ul>
                    </React.Fragment>
                  </div>
                </div>
            </div>
        </div>
        );
    }

}

export default IC_ApplicationShow;
